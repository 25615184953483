
function loginReducer(state, action) {

    switch (action.type) {

        case 'LOGIN_SUCCESSFULL':

            return {
                ...state,
                loading: false
            }

        case 'LOGIN_INITIATED':

            return {
                ...state,
                errorMessage: null,
                loading: true
            }

        case 'LOGIN_FAILED': 
            return {
                ...state,
                loading: false,
                errorMessage: action.errorMessage

            }

        case 'LOGOUT': 
            return {
                ...state,
                loading: false,
                errorMessage: null
            }

        default:
            return state

    }
}

export default loginReducer;
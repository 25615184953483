import React from "react";
import { loginSuccessfull, initiatingLogin, loginFailed } from "./state/loginActions";
import { connect } from "react-redux";
import "./Login.css";
import { withRouter } from "react-router-dom";

class LoginForm extends React.Component {

    constructor(props) {
        super(props);
        const params = new URLSearchParams(window.location.search);

        this.state = {
            registrationRedirect: (params.get('registrationRedirect') === "true"),
            redirectTo : params.get('redirectTo') && params.get('redirectTo').includes('reset') ? "/financni-vykazy-firem" : params.get('redirectTo')
        }

    }

    handleSubmit = e => {

        e.preventDefault();

        const username = e.target.uname.value;
        const password = e.target.upass.value;

        this.props.initiateLogin(username, password, this.props.history, this.state.redirectTo);

    }

    render() {

        return (
            <div className="Form">

                <form className="loginForm" onSubmit={this.handleSubmit}>


                {this.state.registrationRedirect ?
                    <div className="successfullRegistrationBanner"><p>Registrace byla úspěšně dokončena. Nyní se můžete přihlásit.</p></div>
                    : ""
                }

                    <input className="FormInput" placeholder="Email" type="text" name="uname" />
                    <input className="FormInput" placeholder="Heslo" type="password" name="upass" />
                    <p className="termsDiv centerAlignedColumn">Přihlášením souhlasíte s&nbsp;<a href="https://www.offiseek.cz/podminky/financni-vykazy/obchodni-podminky" target="_blank">obchodními podmínkami</a></p>
                    {this.props.errorMessage ? <p className="ErrorMessage">{this.props.errorMessage}</p> : null}

                    {!this.props.loading ?

                        <input className="FormSubmit" type="submit" value="Přihlásit se" />

                        :
                        <div className="FormSubmit" >
                            <div className="buttonLoaderWrapper">
                                <div className="loader" />
                            </div>
                        </div>


                    }
                </form>

                <div className="signupBanner">
                    <p>Nemáte účet? <a href="/registrace">Zaregistrujte se.</a></p>
                    <p><a href="/reset-hesla-email">Zapomněli jste heslo?</a></p>
                </div>
            </div>
        )
    }


}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(LoginForm));


function mapStateToProps(state) {

    return {
        errorMessage: state.login.errorMessage,
        loading: state.login.loading
    }

}

function mapDispatchToProps(dispatch) {


    return {

        initiateLogin: (username, password, history, redirectTo) => {

            dispatch(initiatingLogin());

            login(username, password)
                .then(data => {
                    localStorage.setItem("username", data.username);
                    localStorage.setItem("token", data.token);
                    dispatch(loginSuccessfull(data.username, data.token));

                    if(redirectTo){
                        history.push(redirectTo)
                    }
                })
                .catch(error => dispatch(loginFailed(error.message)));

            

        }
    }

}



function login(username, password) {

    return fetch(`${process.env.REACT_APP_API_URL}/api/v1/users/login`, {
        method: 'post',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(
            {
                username: username,
                password: password
            }
        )
    }).then(response => {

        if (response.status >= 200 && response.status < 300) {

            return response
        }
        if (response.status === 400) {

            const error = new Error(`Nesprávné přihlašovací údaje, prosím zkontrolujte email a heslo.`);
            error.status = response.statusText;
            error.response = response;
            throw error;


        }
        if (response.status === 500) {

            const error = new Error(`Interní chyba na straně aplikace.`);
            error.status = response.statusText;
            error.response = response;
            throw error;

        }
        else {

            const error = new Error(`Neznámá chyba, kontaktujte správce aplikace.`);
            error.status = response.statusText;
            error.response = response;
            throw error;

        }

    })
        .then(response => response.json())
        .then(data => {

            localStorage.setItem('token', data.token);
            localStorage.setItem('username', username);

            return {
                token: data.token,
                username: username
            }

        }
        )
}
import React from "react";
import MetricCircle from "../CompanyDetail/MetricCircle";
import "./MunicipalityMetricsBlock.css";

class MunicipalityMetricsBlock extends React.Component {

    constructor(props) {
        super(props);

        this.czechEnglishMapping = {
            "aktiva_celkem": "assets_total",
            "cizi_zdroje": "other_sources",
            "vysledek_hospodareni_bezneho_ucetniho_obdobi": "profit_period",
            "vysledek_hospodareni_pred_zdanenim": "profit_before_taxes",
            "vlastni_kapital": "equity",
            "vynosy_celkem": "income",
            "naklady_celkem": "expenses",
            "dlouhodobe_zavazky": "obligations",
            "rezervy": "reserves"
        }

        this.assetsKeys = ['aktiva_celkem'];
        this.liabilitiesKeys = ['cizi_zdroje', "vlastni_kapital", "dlouhodobe_zavazky", "rezervy"];
        this.profitKeys = ['vynosy_celkem', 'vysledek_hospodareni_bezneho_ucetniho_obdobi', 'vysledek_hospodareni_pred_zdanenim'];
        this.lossKeys = ["naklady_celkem"]

        this.metrics = {
            "expenses_per_capita": {
                "label": "Běžné výdaje na obyvatele",
                "description": "Pomocí ukazatele lze zjistit výši běžných výdajů, které samospráva v daném roce vynaloží v přepočtu na 1 obyvatele: Běžné výdaje na obyvatele = (výdaje / počet obyvatel)",
                "format": "thousands"

            },
            "saldo": {
                "label": "Saldo",
                "description": "Rozdíl mezi příjmy a výdaji. Kladnou hodnotou se rozumí přebytek, zápornou schodek. Hodnotě salda musí vždy odpovídat hodnota financování (s opačným znaménkem). Vypočítá se dle vzorce: Saldo = (příjmy - náklady).",
                "format": "thousands"
            },
            "total_indebtness": {
                "label": "Podíl cizích zdrojů k celkovým aktivům",
                "description": "Doporučená hranice: hodnota by neměla překročit 25 % včetně. Vypočítá se dle vzorce: Podíl cizích zdrojů k celkovým aktivům = (cizí zdroje / aktiva) * 100",
                "format": "percentage"
            },
            "assets_total": {
                "label": "Aktiva",
                "description": "Aktiva v podstatě představují celkový majetek společnosti. Obsahují vše, co společnost nabyla minulými aktivitami a co v budoucnu přinese ekonomický prospěch.",
                "format": "thousands"
            }

        }

        this.metricOrder = ['expenses_per_capita', 'saldo', 'total_indebtness', 'assets_total']
    }

    goToEconomicMarkers = () => {
        this.props.switchTab('nav_list_tab_Finanční_Stav');
    }

    checkValidationSources = (items) => {
        if (items) {
            return items.reduce((accumulator, item) => (item.includes("x_validate") || item.includes("x_document_validate") && accumulator), true)
        }
        else { return false }
    }

    getVariablesFromDomain = (domain, variablesList) => {

        var ret = {};
        variablesList.map(v => ret[this.czechEnglishMapping[v]] = this.getMostRecentVariableValue(domain.values[v]));
        return ret;
    }

    getValidationSourcesIntersection = (validationSourcesA, validationSourcesB) => {
        return validationSourcesA.filter(v => validationSourcesB.includes(v));
    }

    calculateMetricsFromYearData = (yearData) => {

        // 1. Initialize values
        // 2. What if user is not signed in?
        // 2a. We still have to check what values have $$$ and what are the validation sources...
        // 3. Calculate metrics if user is signed id...

        var assets = yearData.filter(d => d.id === 'city_assets')[0];
        var liabilities = yearData.filter(d => d.id === 'city_liabilities')[0];
        var loss = yearData.filter(d => d.id === 'city_expenses')[0];
        var profit = yearData.filter(d => d.id === 'city_revenues')[0];

        if (assets && liabilities && loss && profit) {
            const assetsVariables = this.getVariablesFromDomain(assets, this.assetsKeys);
            const liabilitiesVariables = this.getVariablesFromDomain(liabilities, this.liabilitiesKeys);
            const profitVariables = this.getVariablesFromDomain(profit, this.profitKeys);
            const lossVariables = this.getVariablesFromDomain(loss, this.lossKeys);

            const variables = { ...assetsVariables, ...liabilitiesVariables, ...profitVariables, ...lossVariables };

            variables["expenses_per_capita"] = variables["expenses"].value ? { 'value': (variables["expenses"].value / this.props.population / 1000).toFixed(0), 'value_sources': this.getValidationSourcesIntersection(variables['expenses'].value_sources, variables['expenses'].value_sources) } : { 'value': null, "value_sources": [] };
            variables["saldo"] = variables["expenses"].value && variables["income"].value ? { 'value': ((variables['income'].value - variables['expenses'].value) / 1000).toFixed(0), 'value_sources': this.getValidationSourcesIntersection(variables['income'].value_sources, variables['expenses'].value_sources) } : { 'value': null, 'value_sources': [] }
            variables["total_indebtness"] = variables['other_sources'].value && variables['assets_total'].value ? { 'value': variables['other_sources'].value / variables['assets_total'].value, 'value_sources': this.getValidationSourcesIntersection(variables['other_sources'].value_sources, variables['assets_total'].value_sources) } : { 'value': null, 'value_sources': [] };

            Object.keys(variables).map(v => variables[v]['trustworthy'] = this.checkValidationSources(variables[v].value_sources));

            return variables;

        }
        else {
            return null
        }
    }

    getMostRecentVariableValue = (possibleValues) => {
        if (possibleValues) {
            const sortedValues = possibleValues.sort((a, b) => a.year > b.year ? 1 : -1);
            const filteredValues = sortedValues.filter(item => item.value && item.value_sources && !item.value_sources.includes('not_found'));
            const lastValue = filteredValues.at(-1);
            return filteredValues.length > 0 ? { "value": lastValue.value != '$$$' ? parseInt(lastValue.value) : lastValue.value, "value_sources": lastValue.value_sources } : { "value": null, "value_sources": [] };
        } else { return [null, []] }
    }

    render() {

        if (this.props.yearData == null) {
            return (

                <div className="metricsBlock">
                    <h2>Základní finanční metriky subjektu</h2>
                    <div className="metricsHorizontal blurred">
                        <MetricCircle metricName="$$$" />
                        <MetricCircle metricName="$$$" />
                    </div>
                    <p>Metriky jsou vypočítány z vytěžených dat dostupných nahoře v sekci <b>Finanční stav</b>.</p>
                </div>
            )
        }

        const values = this.calculateMetricsFromYearData(this.props.yearData);

        if (values) {
            const filtered = Object.entries(values).filter(([key, value]) => key && key in this.metrics && value.value && value.value != '$$$');
            const sorted = filtered.sort(v => this.metricOrder.indexOf(v[0] ? 1 : -1))
            const metrics = sorted.slice(0, 3).map(metric => <MetricCircle metricName={this.metrics[metric[0]].label}
                metricValue={metric[1].value}
                metricTrustworthiness={metric[1].trustworthy}
                metricDescription={this.metrics[metric[0]].description}
                metricFormat={this.metrics[metric[0]].format}
                isUser={this.props.user !== null}
            />)

            return (
                <div className="metricsBlock">
                    <h2>Základní finanční metriky subjektu</h2>
                    <div className="metricsHorizontal">
                        {metrics}
                    </div>

                    <p>Metriky jsou vypočítány z vytěžených dat dostupných v sekci <b>Finanční stav</b>.</p>
                </div>
            )
        } else {
            return null;
        }
    }
}

export default MunicipalityMetricsBlock;

import React from "react";
import "./LimitsExceededBanner.css";

class ErrorLimitationBanner extends React.Component {

    showLimitationBanner() {

        if (!this.props.token) {
            return (
                <div className="limitationBanner limitsExceeded">
                    <p>Překročili jste počet dotazů ZDARMA</p>
                </div>

            )
        }
        else {
            return null
        }

    }

    render() {
        return (
            this.showLimitationBanner()
        )
    }

}

export default ErrorLimitationBanner;
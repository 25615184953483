import React from "react";
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';

class CustomTooltip extends React.Component{

    render(){

        const MyTooltip = styled(({ className, ...props }) => (
            <Tooltip {...props} classes={{ popper: className }} />
          ))(({ theme }) => ({
            [`& .${tooltipClasses.tooltip}`]: {
              backgroundColor: '#f5f5f9',
              color: 'rgba(0, 0, 0, 0.87)',
              maxWidth: 220,
              fontSize: '14px',
              border: '1px solid #dadde9'
            },
          }));

        return (
            <MyTooltip {...this.props} />
        )

    }

}

export default CustomTooltip;
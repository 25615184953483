import React from "react";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import "./DocumentsStats.css";
import CustomTooltip from "../Components/CustomTooltip";
import { Link } from "react-router-dom";
import { Alert } from "react-bootstrap";

class DocumentsStats extends React.Component {

    detectFinancialStatements = (cleanedNames) => {
        if (cleanedNames.filter(name => name.includes("ucetniuzaverka") || name.includes("ucetnizaverka") || name.includes("vyrocnizprava")).length > 0) {
            return true
        }
        else {
            return false
        }
    }

    detectBalance = (cleanedNames) => {
        if (cleanedNames.filter(name => name.includes("rozvaha") || name.includes(" r ")).length > 0) {
            return true
        }
        else {
            return false
        }
    }

    detectProfitLoss = (cleanedNames) => {
        if (cleanedNames.filter(name => name.includes("vykazziskuaztrat") || name.includes("vzaz") || name.includes("vykazziskuztrat") || name.includes("vzz") || name.includes(" vz ")).length > 0) {

            return true
        }
        else {
            return false
        }
    }

    detectDocumentsForLastThreeYears = (yearDocuments) => {
        const currentYear = new Date().getFullYear();
        for (let i = 0; i < 3; i++) {
            const dataPublished = (currentYear - i) in yearDocuments && (yearDocuments[currentYear - i]['financialStatements'] || yearDocuments[currentYear - i]['balance'] || yearDocuments[currentYear - i]['profitLoss'])
            if (dataPublished) {
                return true

            }
        }
        return false
    }

    render() {

        const detectedDocumentsPerYear = {};
        this.props.documents.map(document => {
            const cleanedNames = document.metadata.sources.map(d => d.or_description.normalize("NFD").replace(/\p{Diacritic}/gu, "").replace(/\s+/g, "").toLowerCase())
            const yearData = {}
            yearData["financialStatements"] = this.detectFinancialStatements(cleanedNames);
            yearData["balance"] = this.detectBalance(cleanedNames);
            yearData["profitLoss"] = this.detectProfitLoss(cleanedNames);
            detectedDocumentsPerYear[document.year] = yearData;
        })

        const lastThreeYearsPublished = this.detectDocumentsForLastThreeYears(detectedDocumentsPerYear);


        return (

            <div className="documentsStatsBlock">
                <h2>Publikované finanční dokumenty</h2>

                {!lastThreeYearsPublished ?
                    <Alert variant={'danger'}>
                        Dle názvů dokumentů se zdá, že subjekt nepublikoval finanční výkazy za minulý a předminulý rok.
                        Radši si subjekt prověřte.
                    </Alert> : null}

                {this.props.documents && this.props.documents.length > 0 ?

                    <table className="documentsStatsTable">
                        <thead>
                            <tr>
                                <th>Rok</th>
                                <th>Účetní závěrka</th>
                                <th>Rozvaha</th>
                                <th>Výkaz zisku ztrát</th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.props.documents.sort((a, b) => a.year < b.year ? 1 : -1).map((document, idx) => {

                                return (<tr key={idx}>
                                    <td><Link to={`${new URL(document.url.replace("/api/v1/documents", "/dokumenty")).pathname}`}>{document.year}</Link></td>
                                    <td>{detectedDocumentsPerYear[document.year]['financialStatements'] ? <CustomTooltip title={"Vypadá, že soubor obsahující účetní závěrku pro daný rok byl nahrán do sbírky listin."}><CheckCircleIcon style={{ color: "var(--success)" }} /></CustomTooltip> : <CustomTooltip title={"Vypadá, že soubor s účetní závěrkou pro daný rok nebyl nahrán do sbírky listin."}><div className="dot"></div></CustomTooltip>}</td>
                                    <td>{detectedDocumentsPerYear[document.year]['balance']  ? <CustomTooltip title={"Vypadá, že soubor obsahující rozvahu pro daný rok byl nahrán do sbírky listin."}><CheckCircleIcon style={{ color: "var(--success)" }} /></CustomTooltip> : <CustomTooltip title={detectedDocumentsPerYear[document.year]['financialStatements']  ? " Rozvaha je pravděpodobně součástí účetní závěrky." : "Vypadá, že soubor s rozvahou pro daný rok nebyl nahrán do sbírky listin."}><div className="dot"></div></CustomTooltip>}</td>
                                    <td>{detectedDocumentsPerYear[document.year]['profitLoss']  ? <CustomTooltip title={"Vypadá, že soubor obsahující výkaz zisku a ztrát pro daný rok byl nahrán do sbírky listin."}><CheckCircleIcon style={{ color: "var(--success)" }} /></CustomTooltip> : <CustomTooltip title={detectedDocumentsPerYear[document.year]['financialStatements'] ? " Výkaz zisku a ztrát je pravděpodobně součástí účetní závěrky." : "Vypadá, že soubor s výkazem zisku a ztrát pro daný rok nebyl nahrán do sbírky listin."}><div className="dot"></div></CustomTooltip>}</td>
                                </tr>)
                            })}
                        </tbody>
                    </table>

                    : <div className="noDocumentsDiv"><p>Vypadá, že ve sbírce listin není za poslední roky jediný dokument obsahující finanční výkazy.</p></div>}

            </div>
        )
    }

}

export default DocumentsStats;

import { Helmet } from 'react-helmet';

const ProvereniFirmyRiziko = () => {

    const generatedContent = "Prověřte si obchodního partnera zdarma. Zdarma umožňujeme analýzu rizikovosti firem.";
    const title = "Prověření rizikovosti firmy zdarma";
    const canonicalUrl = "https://financni-vykazy.cz/provereni-firmy-riziko";

    const metadata = <Helmet>
        <title>{title}</title>
        <meta name="description" content={generatedContent} />
        <meta name="og:description" content={generatedContent} />
        <meta name="og:title" content={title} />
        <meta property="og:type" content="website" />
        <link href={canonicalUrl} rel="canonical"></link>
    </Helmet>

    return (
        <div className="blogContent">

            {metadata}

            <div className='blogContainer'>
                <h1>Prověření rizikovosti firmy zdarma</h1>

                <p className='blogText'>
                    <a href="/">Databáze firem zdarma</a> umožňuje prověření rizikovosti firem zdarma.
                </p>

                <img className="blogImage" src={require("./riziko_firmy_dle_financnich_ukazatelu.png")} />

                <p className='blogText'>
                    Pokud bylo možné získat finanční data za poslední roky, tak je Vám zobrazíme přehledně v grafech.
                </p>

                <img className="blogImage" src={require("./riziko_firmy_dle_publikovanych_dokumentu.png")} />

                <p className='blogText'>
                    Pokud firma nepublikovala účetní závěrky za poslední dvě učetní období, tak Vám zobrazíme upozornění.
                </p>

            </div>

        </div>
    )
}

export default ProvereniFirmyRiziko;
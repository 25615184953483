import React from "react";
import { connect } from 'react-redux';
import { getDocumentsAction } from "./state/uploadActions";
import { checkStatus } from '../helpers';
import moment from 'moment';
import LoopIcon from '@mui/icons-material/Loop';
import DoneIcon from '@mui/icons-material/Done';
import { withRouter } from "react-router";
import CloseIcon from '@mui/icons-material/Close';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import CustomTooltip from "../Components/CustomTooltip";
import {generateHeader} from "../helpers";

import './DocumentUploadList.css';

class DocumentUploadList extends React.Component {

    componentDidMount() {
        this.interval = setInterval(() => this.props.getDocuments(this.props.queuedDocuments, this.props.token), 5000);
    }

    componentWillUnmount() {
        clearInterval(this.interval)
    }

    handleRowOnClick = (url) => {
        const u = new URL(url)
        this.props.history.push(u.pathname);
    }

    render() {


        const rows = this.props.queuedDocuments.map(document => {

            if (document.status !== 'processed') {

                return (
                    <CustomTooltip title="Detail je možné zobrazit až v momentě, kdy se dokument úspěšně zpracuje. Odkaz na dokument si můžete zkopírovat kliknutím na ikonku vpravo." arrow>
                        <tr className="disabledTableRow" key={document.url}>
                            <td>{document.status === 'processing' ? <LoopIcon className="loading" style={{ fontSize: 35 }} /> : <CloseIcon style={{ fontSize: 35, color: 'var(--error)' }} />}</td>
                            <td>{document.filename}</td>
                            <td>{document.created_at ? moment(document.created_at).format('DD/MM/YYYY hh:mm:ss') : null}</td>
                            <td className="copyIconTd">
                                <div className="copyIcon" onClick={() => navigator.clipboard.writeText(`${document.url}`)}>
                                    <ContentCopyIcon />
                                </div>
                            </td>
                        </tr>
                    </CustomTooltip>
                )
            }
            else {

                return (
                    <tr className="clickableTableRow" key={document.url} onClick={() => (this.handleRowOnClick(`${document.url.replace("/api/v1/documents", "/dokumenty")}`))}>
                        <td><DoneIcon style={{ fontSize: 35 }} /></td>
                        <td>{document.filename}</td>
                        <td>{document.created_at ? moment(document.created_at).format('DD/MM/YYYY hh:mm:ss') : null}</td>
                        <td className="copyIconTd">
                            <div className="copyIcon" onClick={() => navigator.clipboard.writeText(`${document.url}`)}>
                                <ContentCopyIcon />
                            </div>
                        </td>
                    </tr>
                )
            }
        }
        )

        return (
            <div className="tableList">
                <table>
                    <thead>
                        <tr>
                            <th>Status</th>
                            <th>Název</th>
                            <th>Datum nahrání</th>
                            <th>Odkaz</th>
                        </tr>
                    </thead>
                    <tbody>
                        {rows}
                    </tbody>
                </table>
            </div>
        )
    }

}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(DocumentUploadList));


function mapStateToProps(state) {

    return {
        queuedDocuments: state.documentUpload.queuedDocuments,
        token: localStorage.getItem('token')
    }

}

function getAllDocuments(documents, token) {


    const getReqs = documents.map(document => {
        const d = getDocument(document.url.split("/").pop(), token);
        return d;
    });

    // do fetch requests in parallel
    // using the Promise.all() method
    return Promise.all(getReqs)

}

function getDocument(document_id, token) {

    return fetch(`${process.env.REACT_APP_API_URL}/api/v1/documents/${document_id}`, {
        method: 'get',
        headers: generateHeader(token)
    }).then(checkStatus)
        .then(response => response.json())
}

function mapDispatchToProps(dispatch) {

    return {
        getDocuments: (documents, token) => {

            if (documents.length) {
                getAllDocuments(documents, token).then(documents => dispatch(getDocumentsAction(documents)));
            }
        }
    }

}
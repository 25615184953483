import React from "react";
import * as XLSX from 'xlsx';
import "./XlsxDownloadButton.css";
import { getDomainById } from "../helpers";

class XlsxDownloadButton extends React.Component {

    createXlsxSheet(filename, domainsData, tables) {

        this.props.revealAdverts();

        const workbook = XLSX.utils.book_new();

        const domainsDefinition = {
            "vykaz_zisku_ztrat": [{ "id": "current_value", "label": "Hlavní rok" }, { "id": "previous_value", "label": "Předchozí rok" }],
            "aktiva": [{ "id": "current_netto", "label": "Netto" }, { "id": "current_brutto", "label": "Brutto" }, { "id": "current_korekce", "label": "Korekce" }, { "id": "previous_netto", "label": "Minulé Netto" }],
            "pasiva": [{ "id": "current_value", "label": "Hlavní rok" }, { "id": "previous_value", "label": "Předchozí rok" }]
        }

        Object.entries(domainsDefinition).map(([domainId, domainValuesDefinition]) => {

            const sheet = XLSX.utils.json_to_sheet([{}], {});

            const domainLabels = getDomainById(domainId);

            domainsData.filter(domain => domain.domain_id === domainId).map(
                domain => {
                    let domainData = []

                    if (domain.data.length === 0) {
                        domainData = Object.entries(domainLabels).map(([key, value]) => {
                            const itemJson = { "name": value.label, "id": key, "document_text": "" };
                            domainValuesDefinition.map(domainValueDefinition => itemJson[domainValueDefinition.id] = "")
                            return itemJson
                        })

                    }
                    else {
                        domainData = domain.data.map(item => {

                            const itemJson = { "name": domainLabels[item.id].label, "id": item.id, "document_text": item.text };
                            domainValuesDefinition.map(domainValueDefinition => item.values.filter(itemValue => itemValue.id === domainValueDefinition.id).map(itemValue => {
                                itemJson[itemValue.id] = itemValue.value;
                            }))

                            return itemJson
                        })
                    }
                    XLSX.utils.sheet_add_json(sheet, domainData);
                    XLSX.utils.book_append_sheet(workbook, sheet, domainId);

                }
            )

        }
        )

        tables.map((table, idx) => {

            const sheet = XLSX.utils.json_to_sheet([{}], {});

            var tableArray = new Array(table.row_count).fill('').map(row => new Array(table.column_count).fill(''));

            table.cells.map(cell => {
                tableArray[cell.row_index][cell.column_index] = cell.content
                /*console.log(cell.content);*/
            });

            const rows_d = tableArray.map(row => {
                const row_d = {}
                row.map((cell, idx) => row_d[`column_${idx}`] = cell);
                return row_d
            });

            XLSX.utils.sheet_add_json(sheet, rows_d, { origin: 'A1' });

            XLSX.utils.book_append_sheet(workbook, sheet, `Table ${idx}`);

        });

        XLSX.writeFile(workbook, `${filename}.xls`);

    }

    render() {

        return (

            <button className="downloadXlsx" onClick={(e) => this.createXlsxSheet(this.props.filename, this.props.data, this.props.tables)}>Stáhnout XLSX</button>

        )

    }

}

export default XlsxDownloadButton;
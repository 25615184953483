import "./Header.css"
import React from "react";
import { connect } from "react-redux";
import { logoout } from "../Authentication/state/loginActions";
import { withRouter } from "react-router";
import SearchIcon from '@mui/icons-material/Search';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import AssessmentIcon from '@mui/icons-material/Assessment';
import LocationCityIcon from '@mui/icons-material/LocationCity';
import { Nav } from "react-bootstrap";
import { Link } from 'react-router-dom';


class HeaderComponent extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            showUserMenu: false,
            showHelpMenu: false
        }
    }

    logout = () => {

        localStorage.removeItem('username');
        localStorage.removeItem('token');
        this.props.logout();
        this.setState({
            showUserMenu: false
        })
        window.location.reload(false);

    }

    showUserMenu = () => {

        if (!this.state.showUserMenu)
            this.setState({
                showUserMenu: true
            })
        else {
            this.setState({
                showUserMenu: false
            })
        }
    }

    showHelpMenu = () => {

        if (!this.state.showHelpMenu)
            this.setState({
                showHelpMenu: true
            })
        else {
            this.setState({
                showHelpMenu: false
            })
        }
    }


    render() {

        return (
            <div className="headerDiv">
                <div className="headlineDiv">
                    <img src={require('../Assets/logo.png')} height="60" alt="Logo icon."></img>
                </div>
                <div className="functionsMenu">
                    <Nav defaultActiveKey="companies">
                            <Nav.Item className="functionsMenuItem">
                                <SearchIcon />
                                <Nav.Link eventKey="companies" as={Link}  to="/firmy"  className="functionsMenuItem">Firmy</Nav.Link>
                            </Nav.Item>
                            <Nav.Item className="functionsMenuItem">
                                <LocationCityIcon />
                                <Nav.Link eventKey="municipalities" as={Link} to="/mesta-obce" className="functionsMenuItem">Města a obce</Nav.Link>
                            </Nav.Item>
                            <Nav.Item className="functionsMenuItem">
                                <FilterAltIcon />
                                <Nav.Link eventKey="filterCompanies" as={Link} to="/filtrovani-firem" className="functionsMenuItem">Filtrování firem</Nav.Link>
                            </Nav.Item>
                            <Nav.Item className="functionsMenuItem">
                                <AssessmentIcon />
                                <Nav.Link eventKey="statistics" as={Link} to="/statistika-zahranicni-firmy-cr" className="functionsMenuItem">ČR - statistiky</Nav.Link>
                            </Nav.Item>
                    </Nav>
                </div>
            </div>
        )

    }


}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(HeaderComponent));


function mapStateToProps(state) {

    return {
        token: localStorage.getItem('token'),
        user: localStorage.getItem('username')
    }

}

function mapDispatchToProps(dispatch) {

    return {

        logout: () => { dispatch(logoout()) }

    }

}
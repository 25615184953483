
import React from "react";
import { Link } from "react-router-dom";
import "./SimilarMunicipalityTile.css";

class SimilarMunicipalityTile extends React.Component {

    render() {

        if (this.props.anonymize) {
            return (
                <div className="tile">
                    <p className="registerText">Ukážeme Vám podobné subjekty. 7 dní zdarma po registraci. Případně si přečtěte <a href="https://www.offiseek.cz/podminky/financni-vykazy/cenik" target="_blank">náš ceník.</a></p>
                    <div className="blur">
                        <h3>{this.props.name.repeat(3)}</h3>
                        <p>{this.props.legal_form.repeat(3)}</p>
                        <p>{this.props.address.repeat(3)}</p>
                        <p>{this.props.equity_capital.repeat(3)}</p>
                    </div>
                </div>
            )
        }

        else {
            return (

                <Link target="_blank" to={`/mesta-obce/${this.props.url.split("/").pop()}/${this.props.web_slug}`}>
                    <div className="tile" >
                        <h3>{trimStringIfNeeded(this.props.name)}</h3>
                        <div className="similarCompanyMetadata">
                            <p>{trimStringIfNeeded(this.props.region)}</p>
                            <p>{trimStringIfNeeded(this.props.population)}</p>
                        </div>
                    </div>
                </Link>
            )
        }
    }

}

function trimStringIfNeeded(input) {

    if (input && input.length > 100) {
        return input.slice(0, 100) + "...";
    }
    else {
        return input
    }

}

export default SimilarMunicipalityTile;

import React, { useEffect, useState } from "react";
import { connect } from 'react-redux';
import { checkStatus } from '../helpers';
import './DocumentDetail.css';
import LimitationBanner from "../UiRestrictions/LimitationBanner";
import RawTablesList from "./RawTablesList";
import Constraint from "./Constraint";
import StatementTable from "./StatementTable";
import DocumentDetailTable from "./DocumentDetailsTable";
import XlsxDownloadButton from "./XlsxDownloadButton";
import DetectedDomainHeader from "./DetectedDomainHeader";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import WarningIcon from '@mui/icons-material/Warning';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { generateHeader } from '../helpers';
import RobotsBanner from "../UiRestrictions/RobotsBanner";
import { Helmet } from 'react-helmet';
import Skeleton from '@mui/material/Skeleton';
import { showLimitError } from "../Limiter/limiterActions";
import BigHorizontalBanner from '../Adverts/SeznamAds/BigHorizontalBanner';
import ErrorLimitationBanner from '../Limiter/LimitsExceededBanner';

const DocumentDetail = (props) => {

    const [uncollapsedTables, setUncollapsedTables] = useState([]);
    const [uncollapsedConstraints, setUncollapsedConstraints] = useState([]);
    const [showFullDomains, setShowFullDomains] = useState([]);

    const advertsCodes = {
        "aktiva": {
            zoneId: 356287,
            id: "ssp-zone-356287"
        },
        "pasiva": {
            zoneId: 356290,
            id: "ssp-zone-356290"
        },
        "vykaz_zisku_ztrat": {
            zoneId: 355939,
            id: "ssp-zone-355939"
        }
    }

    const [document, setDocument] = useState(null);

    useEffect(() => {
        getDocumentData(props.match.params.id, props.token, true).then(document => setDocument(document))
            .catch(error => props.dispatchError())
    }, [])

    const changeCollapseOfTableDiv = (tableId, e) => {

        if (uncollapsedTables.includes(tableId)) {
            setUncollapsedTables(uncollapsedTables.filter(table => table != tableId))
        }
        else {
            setUncollapsedTables([...uncollapsedTables, tableId])
        }

    }

    const showFullDomain = (domainId) => {
        setShowFullDomains([...showFullDomains, domainId])
    }

    const hideFullDomain = (domainId) => {
        setShowFullDomains(showFullDomains.filter(item => item !== domainId))
    }

    const changeCollapseOfConstraintDiv = (constraintId, e) => {

        if (uncollapsedConstraints.includes(constraintId)) {
            setUncollapsedConstraints(uncollapsedConstraints.filter(constraint => constraint != constraintId))
        }
        else {
            setUncollapsedConstraints([...uncollapsedConstraints, constraintId])
        }

    }

    const leaveDocument = () => {
        if (props.history.location.key) {
            props.history.goBack()
        }
        else {
            props.history.push("/")
        }
    }

    const documentVerticalNavigation = <div className="verticalNavigation">
        <div className="navigationBackDiv" onClick={leaveDocument}>
            <ArrowBackIcon fontSize="large" />
        </div>
    </div>

    const metadata = <Helmet>
        <meta name="robots" content="noindex" />
    </Helmet>

    if (props.showLimitError) {
        return <ErrorLimitationBanner />
    }

    if (!document) {
        return (
            <div className="documentDetail">
                {metadata}
                {documentVerticalNavigation}

                <div className="documentDetailMain">

                    <div className='documentSkeleton'>
                        <Skeleton variant="rounded" height={800} />
                    </div>
                </div>
            </div>
        )
    }

    if (document.status != "processed") {
        return (
            <div className="documentDetail">

                {metadata}
                {documentVerticalNavigation}

                <div className="documentDetailMain">

                    <LimitationBanner />
                    <RobotsBanner />
                    <div className="missingDocumentsDiv">
                        <p>Data z dokumentu teprve zpracováváme. Přijďte se na dokument podívat behěm následujících dnů / týdnů. </p>
                        <p className="termsDiv">Dokument je pravděpodobně naskenovaný anebo má velkou velikost. Data z takových souborů postupně připravujeme.</p>
                    </div>

                    <DocumentDetailTable
                        filename={document.filename}
                        createdAt={document.created_at}
                        url={document.url}
                        token={props.token}
                        user={props.user}
                        containsOriginal={document.contains_original}
                    />

                    <div className="downloadButtons">

                        <XlsxDownloadButton
                            filename={document.filename}
                            data={[{ "domain_id": "vykaz_zisku_ztrat", "data": [] }, { "domain_id": "aktiva", "data": [] }, { "domain_id": "pasiva", "data": [] }]}
                            tables={[]}
                        />

                    </div>

                </div>
            </div>
        )
    }

    return (

        <div className="documentDetail">

            {metadata}
            {documentVerticalNavigation}

            <div className={`documentDetailMain`}>

                <LimitationBanner />
                <RobotsBanner />

                <DocumentDetailTable
                    filename={document.filename}
                    createdAt={document.created_at}
                    url={document.url}
                    token={props.token}
                    user={props.user}
                    containsOriginal={document.contains_original}
                />

                <div className="downloadButtons">

                    <XlsxDownloadButton
                        filename={document.filename}
                        data={document.analysis_data.matched_domains}
                        tables={document.analysis_data.tables}
                    />

                </div>

                <BigHorizontalBanner
                    zoneId={355942}
                    id="ssp-zone-355942" />

                {document.analysis_data.matched_domains.map(domain => (<div>

                    <DetectedDomainHeader
                        matchedDomainId={domain.domain_id}
                        detectedYear={domain.year}
                        showFullDomain={showFullDomain}
                        hideFullDomain={hideFullDomain}
                    />

                    {domain.data.filter(item => item.values.reduce(
                        (prev, current) => prev + (current.value != null && current.value != ""), 0)).length > 1 ?

                        domain.unsatisfied_constraints.length > 0 ?

                            <div>
                                {domain.unsatisfied_constraints.map(unsatisfiedConstraint =>
                                    <Constraint
                                        constraintType={unsatisfiedConstraint.id}
                                        onClick={changeCollapseOfConstraintDiv}
                                        unsatisfiedConstraints={unsatisfiedConstraint.unsatisfied_constraints}
                                        uncollapsedList={uncollapsedConstraints}

                                    />
                                )}
                            </div>

                            :

                            <div className="constraintsSuccess">
                                <CheckCircleIcon style={{ color: "var(--success)" }} />
                                <p className="constraintParagraph">Zdá se, že data jsou v pořádku. Všechny kontrolní součty sedí.</p>
                            </div>

                        :
                        <div className="noDataFoundDiv">
                            <WarningIcon style={{ color: "var(--warning)" }} />
                            <p className="constraintParagraph">Zdá se, že v dokumentu se nevyskytují požadovaná data. Doporučujeme si stáhnout originál dokumentu kliknutím na <b>Stáhnout originál</b> a data si ověřit.</p>
                        </div>
                    }

                    <StatementTable
                        domainId={domain.domain_id}
                        data={domain.data}
                        year={domain.year}
                        monetaryOrder={domain.monetary_order}
                        showFull={showFullDomains.includes(domain.domain_id)}
                    />

                    {
                        document && domain.domain_id in advertsCodes ? <BigHorizontalBanner
                            zoneId={advertsCodes[domain.domain_id].zoneId}
                            id={advertsCodes[domain.domain_id].id}
                        /> : null}


                </div>))}

                <div>
                    <hr />
                    <h1>Detekované tabulky</h1>

                    {(document.analysis_data.tables.length > 0) ?

                        <RawTablesList
                            onClick={changeCollapseOfTableDiv}
                            uncollapsedList={uncollapsedTables}
                            tables={document.analysis_data.tables}
                        />

                        :

                        <p>Žádné tabulky nebyly nalezeny.</p>
                    }
                </div>
            </div>
        </div>

    )

}

export default connect(mapStateToProps, mapDispatchToProps)(DocumentDetail);


function mapStateToProps(state) {

    return {
        token: localStorage.getItem('token'),
        user: localStorage.getItem('username'),
        showLimitError: state.limits.showLimitError,
    }

}

function getDocumentData(document_id, token, isOneTime) {

    let url = null;
    if (isOneTime) {
        url = `${process.env.REACT_APP_API_URL}/api/v1/documents/${document_id}?oneTime=true`
    } else {
        url = `${process.env.REACT_APP_API_URL}/api/v1/documents/${document_id}`
    }

    return fetch(url, {
        method: 'get',
        headers: generateHeader(token)
    }).then(checkStatus)
        .then(response => response.json())

}

function mapDispatchToProps(dispatch) {

    return {
        dispatchError: () => {
            dispatch(showLimitError());
        }
    }

}
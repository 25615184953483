
import { Helmet } from 'react-helmet';

const ExportDatFirem = () => {

    const generatedContent = "Nabízíme export dat firem dle tržeb, obratu, aktiv, pasiv či základního kapitálu.";
    const title = "Export dat firem";
    const canonicalUrl = "https://financni-vykazy.cz/export-dat-firem";

    const metadata = <Helmet>
        <title>{title}</title>
        <meta name="description" content={generatedContent} />
        <meta name="og:description" content={generatedContent} />
        <meta name="og:title" content={title} />
        <meta property="og:type" content="website" />
        <link href={canonicalUrl} rel="canonical"></link>
    </Helmet>

    return (
        <div className="blogContent">

            {metadata}

            <div className='blogContainer'>
                <h1>Export dat firem</h1>

                <b>Nabízíme export dat firem dle různých parametrů</b>

                <p className='blogText'>
                    Filtrování firem si můžete vyzkoušet v naší <a href="/filtrovani-firem">webové aplikaci</a>. Kliknutím na tlačítko Export si následně seznam firem můžete stáhnout na Váš počítač ve formátu XLSX.
                </p>

                <p><b>Firmy mohou být exportovány dle:</b></p>

                <ul>
                    <li className="blogLi">Obratu (tržby za prodej výrobků či zboží)</li>
                    <li className="blogLi">Aktiv</li>
                    <li className="blogLi">Pasiv</li>
                    <li className="blogLi">Vlastního kapitálu</li>
                    <li className="blogLi">Oborů činnosti</li>
                </ul>

                <img className="blogImage" src={require("./Export dat firem - financni vykazy.png")} />

                <p>Jsme schopni filtrovat dle všech parametrů dostupných ve finančních výkazech, které se nám povedlo automaticky vytěžit.</p>
                <p><b>Data mohou být exportovány do:</b></p>

                <ul>
                    <li className="blogLi">XLSX</li>
                    <li className="blogLi">JSON</li>
                    <li className="blogLi">XML</li>
                </ul>

                <p>Připadně dalších formátů dle domluvy.</p>

                <p>Udržujeme automaticky generovanou databázi firem a jejich finančních výkazů.</p>

                <p>V případě zájmu o export dat nás kontaktujte na emailové adrese: support@offiseek.com (či telefonicky na číslo +420 728 94 73 28).</p>

                <p>Vyjasníme si s Vámi očekávaný obsah exportu a připravíme cenovou nabídku.</p>
            </div>


        </div>
    )
}

export default ExportDatFirem;
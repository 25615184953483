import React from "react";
import "./MetricCircle.css";
import CustomTooltip from "../Components/CustomTooltip";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import BugReportIcon from '@mui/icons-material/BugReport';

class MetricCircle extends React.Component {

    render() {
        return (
            <CustomTooltip title={this.props.metricDescription}>
                <div className="metricCircle">
                    {this.props.metricTrustworthiness ? <CustomTooltip title='Vypadá, že proměnné použité pro výpočet této metriky jsou správně extrahované z účetních závěrek.'><CheckCircleIcon style={{ color: "var(--success)" }} /></CustomTooltip> : <CustomTooltip title='U dat použitých na výpočet této metriky si nejsme 100% jisti jejich správným automatickým vyčtením. Pro jistotu si data ověřte z účetních závěrek.'><BugReportIcon style={{ color: "var(--warning)" }} /></CustomTooltip>}
                    <span className="metricValue">
                        {(this.props.metricFormat === 'percentage') ? `${(this.props.metricValue * 100).toFixed(2)}%` : `${Intl.NumberFormat('en-GB').format(this.props.metricValue)} tis.`} 
                    </span>
                    <span className="metricName">{this.props.metricName}</span>
                </div>
            </CustomTooltip>
        )
    }

}

export default MetricCircle;
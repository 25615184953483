import React from "react";
import './Footer.css';

class FooterComponent extends React.Component {

    render() {
        return (
            <div className="footerDiv">

                <div className="footerBlock">
                    <b>Služby</b>
                    <a href="/databaze-firem-zdarma">Databáze firem zdarma</a>
                    <a href="/export-dat-firem">Export dat firem</a>
                </div>

                <div className="footerBlock">
                    <b>Zdroje</b>
                    <a href="/financni-vykazy">Co jsou finanční výkazy firem?</a>
                    <a href="/ucetni-vykazy">Co je účetní uzávěrka firem?</a>
                    <a href="/provereni-firmy-riziko">Prověření firem - riziko</a>
                </div>

                <div className="footerBlock">
                    <b>O nás</b>
                    <div><a href="/kontakt">Kontakt</a></div>
                    <div><a href="https://www.offiseek.cz/podminky/financni-vykazy/cenik">Ceník</a></div>
                    <p>Neručíme za správnost poskytovaných dat.</p>
                </div>
            </div>
        )
    }

}

export default FooterComponent;
import { useEffect, useState } from "react";
import { checkStatus } from "../helpers";
import { CircularProgress } from "@mui/material";
import { BarChart, Bar, Rectangle, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { PieChart, Pie, Cell } from 'recharts';
import { generateHeader } from "../helpers";
import activities from '../CompanyFiltering/activities.json';
import './ForeignCompaniesOwners.css';

const StatsDashboard = (props) => {

    const [companiesCounts, setCompaniesCounts] = useState(null);
    const [companiesTurnovers, setCompaniesTurnovers] = useState(null);
    const [sectorTurnovers, setSectorTurnovers] = useState(null);
    const [maxYear, setMaxYear] = useState(null);

    const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042'];

    const numberTickFormatter = (number) => {
        return new Intl.NumberFormat("cs", {
            notation: "compact",
            compactDisplay: "short",
        }).format(number)
    }

    const RADIAN = Math.PI / 180;
    const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, index }) => {
        const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
        const x = cx + radius * Math.cos(-midAngle * RADIAN);
        const y = cy + radius * Math.sin(-midAngle * RADIAN);

        return (
            <text x={x} y={y} fill="white" textAnchor="middle" dominantBaseline="central">
                {numberTickFormatter(companiesTurnoversData[index].obrat)}
            </text>
        );
    };

    const renderSectorCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, index }) => {
        const RADIAN = Math.PI / 180;
        // eslint-disable-next-line
        const radius = 25 + innerRadius + (outerRadius - innerRadius);
        // eslint-disable-next-line
        const x = cx + radius * Math.cos(-midAngle * RADIAN);
        // eslint-disable-next-line
        const y = cy + radius * Math.sin(-midAngle * RADIAN);

        return (
            <text
                x={x}
                y={y}
                fill="#8884d8"
                textAnchor={x > cx ? "start" : "end"}
                dominantBaseline="central"
            >
                {`${sectorTurnoverData[index].name} - ${numberTickFormatter(sectorTurnoverData[index].obrat)}`}
            </text>
        );
    };

    const getSectorCustomizedLegend = () => {

        const labels = sectorTurnoverData.map(item => {

            const activity = activities.filter(a => a.code === item.name)

            return {
                value: `${item.name} ${activity.length > 0 ? activity[0].name : ''}`, type: 'square', id: `${item.name}`, color: '#8884d8'
            }
        }
        )

        labels.sort((a, b) => a.value > b.value ? 1 : -1)
        return labels
    }

    const renderColorfulLegendText = (value, entry) => {
        const { color } = entry;

        return <span style={{ 'color': '#8884d8' }}>{value}</span>;
    }

    useEffect(() => {
        getForeignCompaniesOwnersOverApi().then(response => {
            setCompaniesCounts(response['companies_counts']);
            setCompaniesTurnovers(response['turnovers'])
            setSectorTurnovers(response['business_sectors_turnover'])
            setMaxYear(Math.max(...Object.keys(response['companies_counts'])));
        })


    }, [])

    if (!companiesCounts || !maxYear) {
        return <CircularProgress />
    }

    const companiesCountsData = initCompaniesCountsData(companiesCounts[maxYear], COLORS)
    const companiesTurnoversData = initCompaniesTurnoverData(companiesTurnovers[maxYear])
    const sectorTurnoverData = initSectorsTurnoverData(sectorTurnovers[maxYear])
    const sectorGranularData = initSectorsGranularData(sectorTurnovers[maxYear])

    return (
        <div className="chartsDashboard">
            <h1>Firmy v ČR dle země původu konečných vlastníků</h1>
            <p className="graphDataDescription">Konečným (vrcholovým) vlastníkem je subjekt (podnik nebo fyzická osoba), který v řetězci kontroly již sám není kontrolován jiným subjektem.</p>
            <p className="graphDataDescription">Aktuálně zobrazujeme data z roku <b>2021</b></p>
            {maxYear ?
                <div className="graphContainer">
                    <h3 className="graphHeading">Počet firem dle českých, evropských a zahraničních vlastníků</h3>
                    <ResponsiveContainer height={200} >
                        <BarChart
                            width={500}
                            height={300}
                            data={companiesCountsData}
                            margin={{
                                top: 5,
                                right: 30,
                                left: 20,
                                bottom: 5,
                            }}
                        >
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis dataKey="name" />
                            <YAxis scale="log" domain={['auto', 'auto']} tickFormatter={numberTickFormatter} />
                            <Tooltip formatter={numberTickFormatter} />
                            <Legend />
                            <Bar dataKey="počet" activeBar={<Rectangle fill="pink" stroke="blue" />} />

                        </BarChart>
                    </ResponsiveContainer>
                </div>
                : <CircularProgress />}

            {maxYear ?
                <div className="graphContainer">
                    <h3 className="graphHeading">Obrat firem dle českých, evropských a zahraničních vlastníků</h3>
                    <ResponsiveContainer height={400}>
                        <PieChart width={400} height={400}>
                            <Pie
                                data={companiesTurnoversData}
                                cx="50%"
                                cy="50%"
                                labelLine={false}
                                label={renderCustomizedLabel}
                                outerRadius={140}
                                fill="#8884d8"
                                dataKey="obrat"
                            >
                                {companiesTurnoversData.map((entry, index) => (
                                    <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                                ))}
                            </Pie>
                            <Legend />
                        </PieChart>
                    </ResponsiveContainer></div>

                : <CircularProgress />}

            {maxYear ?
                <div className="graphContainer">
                    <h3 className="graphHeading">Obrat jednotlivých sektorů českých firem</h3>
                    <ResponsiveContainer height={800}>
                        <PieChart width={800} height={800}>
                            <Pie
                                dataKey="obrat"
                                isAnimationActive={false}
                                data={sectorTurnoverData}
                                label={renderSectorCustomizedLabel}
                                cx="50%"
                                cy="50%"
                                outerRadius={140}
                                fill="#8884d8"
                            />
                            <Legend layout="vertical" payload={getSectorCustomizedLegend()} formatter={renderColorfulLegendText} />
                        
                        </PieChart>
                    </ResponsiveContainer></div>

                : <CircularProgress />}

            {maxYear ?
                <div className="graphContainer">
                    <h3 className="graphHeading">Obrat jednotlivých sektorů českých firem</h3>
                    <ResponsiveContainer height={600}>
                        <BarChart
                            width={500}
                            height={500}
                            data={sectorGranularData}
                            margin={{
                                top: 20,
                                right: 30,
                                left: 20,
                                bottom: 5,
                            }}
                        >
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis dataKey="name" />
                            <YAxis tickFormatter={numberTickFormatter} />
                            <Tooltip formatter={numberTickFormatter} />
                            <Legend />
                            <Bar dataKey="CZ" stackId="a" fill={COLORS[0]} />
                            <Bar dataKey="EU (bez CZ)" stackId="a" fill={COLORS[1]} />
                            <Bar dataKey="Mimo EU" stackId="a" fill={COLORS[2]} />
                        </BarChart>
                    </ResponsiveContainer>
                </div> : <CircularProgress/>    
        }

            <p className="graphDataDescription">Data získáváme z <a href="https://www.czso.cz/csu/czso/o-csu">Českého statistického úřadu (ČSÚ)</a></p>

        </div>
    )

}

export default StatsDashboard;

function parseIntFromData(value){
    return parseInt(value) || 0
}


function initCompaniesCountsData(dataDict, COLORS) {

    const data = [
        {
            name: 'CZ',
            počet: parseIntFromData(dataDict['cz']),
            fill: COLORS[0]
        },
        {
            name: 'EU (bez CZ)',
            počet: parseIntFromData(dataDict['eu']),
            fill: COLORS[1]
        },
        {
            name: 'Mimo EU',
            počet: parseIntFromData(dataDict['non_eu']),
            fill: COLORS[2]
        }
    ];

    return data

}

function initCompaniesTurnoverData(dataDict) {

    const data = [
        {
            name: 'CZ',
            obrat: parseIntFromData(dataDict['cz']),
        },
        {
            name: 'EU (bez CZ)',
            obrat: parseIntFromData(dataDict['eu']),
        },
        {
            name: 'Mimo EU',
            obrat: parseIntFromData(dataDict['non_eu']),
        }
    ];

    return data

}

function initSectorsTurnoverData(dataDict) {

    const data = []
    Object.entries(dataDict).map(([key, value]) =>
        data.push(
            { name: key, obrat: parseIntFromData(value['all']) }
        )
    )

    data.sort((a, b) => a.obrat > b.obrat ? 1 : -1)

    return data

}

function initSectorsGranularData(dataDict) {

    const data = []
    Object.entries(dataDict).map(([key, value]) =>
        data.push(
            { name: key, "CZ": parseIntFromData(value['cz']),
             "EU (bez CZ)": parseIntFromData(value["eu"]),
              "Mimo EU": parseIntFromData(value["non_eu"]) }
        )
    )

    return data

}


function getForeignCompaniesOwnersOverApi(token) {
    return fetch(`${process.env.REACT_APP_API_URL}/api/v1/foreign_owners_stats`, {
        method: 'get',
        headers: generateHeader(token)
    }).then(checkStatus)
        .then((response) => response.json())
}
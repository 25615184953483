import React from 'react';
import './BusinessCategoriesBlock.css';
import { generateUrl } from '../CompanyFiltering/helpers';
import { Button } from '@mui/material';
import { cropString } from '../helpers';

class BusinessCategoriesBlock extends React.Component {

    openFilteringTab = (url) => {
        window.open(url, "_blank")
    }


render() {

    if (!this.props.businessCategories || this.props.businessCategories.length == 0) {
        return null;
    }

    return (
        <div className="metricsBlock">
            <h2>Ekonomické činnosti</h2>
            <table className="similarCompaniesTable">
                <thead>
                    <tr>
                        <th>Název činnosti</th>
                        <th>Kód</th>
                        <th>Podobné subjekty dle činnosti</th>
                    </tr>
                </thead>
                <tbody>
                    {this.props.businessCategories.map(category =>
                        <tr>
                            <td>{cropString(category.label)}</td>
                            <td>{category.code}</td>
                            <td><Button className="linkButton" onClick={e => this.openFilteringTab(`/filtrovani-firem?${generateUrl({ activities: [category.code], markers: { turnover: { from: null, to: null } }, years: [2022] })}`)}>Zobrazit subjekty</Button></td>
                        </tr>)}
                </tbody>
            </table>
        </div>
    )
}

}

export default BusinessCategoriesBlock;
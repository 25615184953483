
function uploadDocument(){

    return {
        type: "UPLOAD_INITIALIZE"
    }

}

function uploadDocumentDone(data){
    
    return {
        type: "UPLOAD_DONE",
        data: data
    }

}

function setDocument(document){
    return {
        type: "DOCUMENT_SET",
        document: document
    }
}


function getDocumentsAction(documents){
    return {
        type: "GET_DOCUMENTS",
        documents: documents
    }
}

export {uploadDocument, uploadDocumentDone, setDocument, getDocumentsAction};
import React from "react";
import domainIdLabelMapping from '../domainIdLabelMapping.json';
import "./DetectedDomainHeader.css";
import { Switch } from "@mui/material";
import FormGroup from '@mui/material/FormGroup';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';


class DetectedDomainHeader extends React.Component {

    renderYear = (year) => {
        if (year) return <p>Detekovaný rok: {year}</p>
    }

    controlSwitch = (e) => {
        if (e.target.checked) {
            this.props.hideFullDomain(this.props.matchedDomainId);
        } else {
            this.props.showFullDomain(this.props.matchedDomainId);
        }
    }

    render() {
        return (
            <div className="domainHeaderDiv">
                <h1>{domainIdLabelMapping[this.props.matchedDomainId]}</h1>
                <FormControl component="fieldset">
                <FormGroup>
                    <FormControlLabel
                        control={<Switch sx={{ m: 1 }} defaultChecked onChange={this.controlSwitch} />}
                        label="Zobrazit jen nalezené položky"
                        labelPlacement="end"
                    />
                </FormGroup>
                </FormControl>
                {this.renderYear(this.props.detectedYear)}
            </div>

        )
    }

}

export default DetectedDomainHeader;
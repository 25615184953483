import React, { PureComponent } from 'react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import './CustomLineChart.css'


class CustomLineChart extends PureComponent {

  render() {
    
    return (
        <ResponsiveContainer height={200}>
          <LineChart
            width={500}
            height={300}
            data={this.props.data}
            margin={{
              top: 5,
              right: 30,
              left: 20,
              bottom: 5,
            }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="name" tick={{fill: 'var(--outline)'}} stroke='var(--outline)' strokeWidth={3} tickLine={{stroke: 'var(--outline)'}} style={{fontWeight: 'bold'}} />
            <YAxis label={{value: 'CZK tis.', angle: '-90', fill: 'var(--outline)', fontWeight: 'bold', position: 'insideLeft'}} tick={{fill: 'var(--outline)'}} stroke='var(--outline)' strokeWidth={3} tickLine={{stroke: 'var(--outline)'}} style={{fontWeight: 'bold'}} />
            <Tooltip />
            <Line type="monotone" dataKey="hodnota" stroke="var(--secondary)" strokeWidth={3} activeDot={{ r: 8 }} />
          </LineChart>
        </ResponsiveContainer>
    );
  }
}

export default CustomLineChart;
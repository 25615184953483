import React from "react";
import moment from "moment";
import DeleteIcon from '@mui/icons-material/Delete';
import CustomTooltip from "./CustomTooltip";
import './DeletedIcon.css';

class CustomDeletedIcon extends React.Component {

    render() {

        return (
            <CustomTooltip title={`Výmaz společnosti z obchodního rejstříku proběhl dne ${moment(this.props.deletedAt).format('DD/MM/YYYY')}`} >
                {(this.props.locationType === "dashboard") ?
                <div className='deletedCompany deletedCompanyDashboard'>
                    <DeleteIcon fontSize='small' />
                </div>
                :
                <div className='deletedCompany'>
                <DeleteIcon fontSize='medium' />
                </div>
                }
            </CustomTooltip>
        )

    }

}

export default CustomDeletedIcon;
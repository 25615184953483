
function limitReducer(state, action){

    switch(action.type){

        case 'SHOW_LIMIT_ERROR':

            return {
                ...state,
                showLimitError: true
            }

        default:
            return state

    }

}

export {limitReducer};
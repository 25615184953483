import React from 'react';
import { cropString } from '../helpers';
import { Button } from '@mui/material';
import './SimilarMunicipalitiesBlock.css';

class SimilarMunicipalitiesBlock extends React.Component {

    openFilteringTab = (url) => {
        window.open(url, "_blank")
    }

    render() {

        if(!this.props.municipalities || this.props.municipalities.length == 0){
            return null;
        }

        return (
            <div className="metricsBlock">
                <h2>Podobné municipality</h2>
                <table className="similarCompaniesTable">
                    <thead>
                        <tr>
                            <th>Název</th>
                            <th className='legalFormTd'>Kraj</th>
                            <th>Počet obyvatel</th>
                        </tr>
                    </thead>
                    <tbody>
                        {this.props.municipalities.map(company =>
                            <tr>
                                <td>{cropString(company.name)}</td>
                                <td>{cropString(company.region)}</td>
                                <td>{cropString(company.population)}</td>
                                <td><Button className="linkButton" onClick={e => this.openFilteringTab(`/mesta-obce/${company.url.split("/").pop()}/${company.web_slug}`)}>Otevřít</Button></td>
                            </tr>)}
                    </tbody>
                </table>
            </div>
        )
    }

}

export default SimilarMunicipalitiesBlock;
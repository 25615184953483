import React from "react";
import './CompanyDetailBlock.css';
import moment from 'moment';
import OrLink from './OrLink';


class CompanyDetailBlock extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            "uncollapsedBusinessType": false,
            "uncollapsedActivityType": false
        }
    }

    controlCollapse = (e) => {
        if (e.target.id === "businessTypeControl") {
            this.setState({ ...this.state, "uncollapsedBusinessType": !this.state.uncollapsedBusinessType })
        }
        else {
            this.setState({ ...this.state, "uncollapsedActivityType": !this.state.uncollapsedActivityType })
        }
    }


    render() {

        if (!this.props.orCompanyUrl) {
            return (

                <div>
                    <table className="detailsTable">
                        <tbody>
                            <tr>
                                <td className="headingCell">Název:</td>
                                <td>{this.props.name}</td>
                            </tr>
                            <tr>
                                <td className="headingCell">IČO:</td>
                                <td>{this.props.id}</td>
                            </tr>
                            <tr>
                                <td className="headingCell">URL:</td>
                                <td className="cellUrl"><a href={this.props.url}>{`${window.location.hostname}/firmy/.../${this.props.slug}`}</a></td>
                            </tr>

                        </tbody>
                    </table>
                </div>

            )
        }

        const businessType = this.props.businessType ? this.props.businessType.map(bt => (<li>{bt}</li>)) : null;
        const activityType = this.props.activityType ? this.props.activityType.map(bt => (<li>{bt}</li>)) : null;

        const orUrl = new URL(this.props.orCompanyUrl)
        const orSearchParams = new URLSearchParams(orUrl.search)
        const orId = orSearchParams.get('subjektId')
        const esmUrl = `https://esm.justice.cz/ias/issm/rejstrik-sm.vysledky?subjektId=${orId}&typ=PLATNY_CASTECNY_SM`

        return (
            <div>
                <table className="detailsTable">
                    <tbody>
                        <tr>
                            <td className="headingCell">Název:</td>
                            <td>{this.props.name}</td>
                        </tr>
                        <tr>
                            <td className="headingCell">IČO:</td>
                            <td>{this.props.id}</td>
                        </tr>
                        <tr>
                            <td className="headingCell">Adresa:</td>
                            <td>{this.props.address}</td>
                        </tr>
                        <tr>
                            <td className="headingCell">URL:</td>
                            <td className="cellUrl"><a href={this.props.url}>{`${window.location.hostname}/firmy/.../${this.props.slug}`}</a></td>
                        </tr>
                        <tr>
                            <td className="headingCell">Den vzniku:</td>
                            <td>{this.props.createdAt ? moment(this.props.createdAt).format('DD/MM/YYYY') : null}</td>
                        </tr>
                        {
                            this.props.deletedAt ?
                                <tr>
                                    <td className="headingCell">Den výmazu:</td>
                                    <td>{moment(this.props.deletedAt).format('DD/MM/YYYY')}</td>
                                </tr>
                                : null
                        }
                        <tr>
                            <td className="headingCell">Právní forma:</td>
                            <td>{this.props.legalForm}</td>
                        </tr>
                        <tr>
                            <td className="headingCell">Předmět podnikání: </td>
                            <td>
                                {(!businessType || businessType.length <= 1) ? <ul className="businessData">{businessType}</ul> :

                                    this.state.uncollapsedBusinessType ? <div><ul className="businessData">{businessType}</ul>
                                        <div className="controlShow"><span id="businessTypeControl" onClick={this.controlCollapse}>Zobrazit méně</span></div>
                                    </div> :
                                        <div><ul className="businessData">{businessType[0]}</ul>
                                            <div className="controlShow"><span id="businessTypeControl" onClick={this.controlCollapse}>Zobrazit všechny ({businessType.length})</span></div>
                                        </div>
                                }
                            </td>
                        </tr>
                        <tr>
                            <td className="headingCell">Předmět činnosti:</td>
                            <td>
                                {(!activityType || activityType.length <= 1) ? <ul className="businessData">{activityType}</ul> :

                                    this.state.uncollapsedActivityType ? <div><ul className="businessData">{activityType}</ul>
                                        <div className="controlShow"><span id="activityTypeControl" onClick={this.controlCollapse}>Zobrazit méně</span></div>
                                    </div> :
                                        <div><ul className="businessData">{activityType[0]}</ul>
                                            <div className="controlShow"><span id="activityTypeControl" onClick={this.controlCollapse}>Zobrazit všechny ({activityType.length})</span></div>
                                        </div>
                                }
                            </td>

                        </tr>
                        <tr>
                            <td className="headingCell">Číslo spisu:</td>
                            <td>{this.props.fileId}</td>
                        </tr>
                        <tr>
                            <td className="headingCell">Základní kapitál:</td>
                            <td>{this.props.equityCapital}</td>
                        </tr>

                    </tbody>
                </table>
                {
                    this.props.orCompanyUrl ? <OrLink url={this.props.orCompanyUrl} label="Odkaz na Obchodní rejstřík" /> : null
                }
                {
                    this.props.orCompanyUrl ? <OrLink url={esmUrl} label="Odkaz na Evidenci skutečných majitelů" /> : null
                }
                <OrLink url="https://adisspr.mfcr.cz/adis/jepo/epo/dpr/apl_ramce.htm?R=/dpr/DphReg?ZPRAC=FDPHI1%26poc_dic=2%26OK=Zobraz" label="Zajímá Vás, zda je firma plátce DPH? Případně, že je spolehlivý plátce?" />
                <OrLink url="https://isir.justice.cz/isir/common/index.do" label="Je firma dlužníkem, proti kterému bylo zahájeno insolvenční řízení?" />
            </div>
        )
    }

}

export default CompanyDetailBlock;
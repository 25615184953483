

function filterReducer(state, action){

    if(action.type === 'FILTER_INITIALIZE'){

        return {...state,
            isLoading: true,
            cutOff: false,
            cutOff: null
            }
    }

    else if(action.type === 'FILTER_DONE'){

        return {
            ...state,
            isLoading: false,
            companies: action.companies,
            companiesTotalCount: action.companiesTotalCount,
            companiesPageCount: action.companiesPageCount,
            cutOff: action.cutOff,
            cutOffLimit: action.cutOffLimit
}
        
    }
    else{

        return state;

    }

}

export default filterReducer;
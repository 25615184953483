import React from "react";
import './MunicipalitySearchGuide.css';
import MunicipalityRegionTile from "./MunicipalityRegionTile";

const regionsMap = {
    "7ZdEekqy1mk2vYwm/00064581-hlavni-mesto-praha": "Praha",
    "nO281R6PGl5gMm9Y/44992785-brno": "Brno",
    "Z6n9zR0lAPRKvWjd/00845451-ostrava": "Ostrava",
    "nroNmkGYN9BdgwOJ/00075370-plzen": "Plzen",
    "G39a75lXQW58XlWY/00262978-liberec": "Liberec",
    "V4Q6r5vL1OR2JmWj/00299308-olomouc": "Olomouc",
    "7K0Gp5Q8Lzkr9Veg/00081531-usti-nad-labem": "Ústí nad Labem",
    "YG7zp52MEEk3Mj06/00268810-hradec-kralove": "Hradec Králové"
}

class MunicipalitySearchGuide extends React.Component {

    handleRowOnClick = (url) => {

        const u = new URL(url)
        this.props.history.push(u.pathname);

    }

    render() {
        return (
            <div className="searchGuideMain">
                                <div className="searchGuideOffer">
                    <div className="searchGuideTile oneReport">
                        <h3>Firmy</h3>
                        <p>Až 30krát denně můžete prozkoumat vytěžená data firem <b>zdarma</b>.</p>
                        <b>Bez relkam?</b> <a href="https://www.offiseek.cz/podminky/financni-vykazy/cenik" target="_blank">Zkontrolujte ceník.</a>
                    </div>
                    <div className="searchGuideTile onlinePlatform">
                        <h3>Města a obce</h3>
                        <p>Zajímá Vás hospodaření vašeho města nebo obce?</p>
                        <b>Vyhledávejte</b> <a href="/mesta-obce" target="_blank">zde.</a>
                    </div>
                    <div className="searchGuideTile exportData">
                        <h3>Statistiky ČR</h3>
                        <p>Jak se daří segmentům české ekonomiky? Kolik vlastníků je z ČR?</p>
                        <a href="/statistika-zahranicni-firmy-cr" target="_blank">Více informací</a>
                    </div>
                </div>

                <div className="regionsBlock">
                    <h2>Města</h2>
                    <div className="regionsBlock">
                        {Object.entries(regionsMap).map(([key, value]) => <MunicipalityRegionTile
                            regionName={value}
                            regionKey={`mesta-obce/${key}`} />)}
                    </div>
                </div>
            </div>
        )
    }

}

export default MunicipalitySearchGuide;
import React from "react";
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import './RawTable.css';

class RawTable extends React.Component {

    render() {
        return (
            <div id={`table_${this.props.idx}`} className="collapsableDiv">

                <div className="collapsableDivHeading" onClick={e => this.props.onClick(`table_${this.props.idx}`, e)}>
                    {
                        (this.props.uncollapsedList.includes(`table_${this.props.idx}`)) ?
                            <ArrowDropDownIcon className="collapseArrow" style={{ fontSize: 50 }} />
                            :
                            <ArrowRightIcon className="collapseArrow" style={{ fontSize: 50 }} />
                    }

                    <p className="collapsableDivText">Tabulka č. {this.props.idx + 1}, stránka {this.props.tablePage}</p>
                </div>

                <div className={`${this.props.uncollapsedList.includes(`table_${this.props.idx}`) ? "uncollapsedDiv" : "collapsedDiv"}`}>
                    <table className="dataTable">
                        <thead></thead>
                        <tbody>
                            {this.props.tableRows}
                        </tbody>
                    </table>
                </div>
            </div>

        )
    }

}

export default RawTable;
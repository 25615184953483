import './Blog.css';
import { Helmet } from 'react-helmet';

const FinancniVykazy = () => {

    const generatedContent = "Co jsou finanční výkazy a z čeho se skládájí?";
    const title = "Finanční výkazy";
    const canonicalUrl = "https://financni-vykazy.cz/ucetni-vykazy";

    const metadata = <Helmet>
        <title>{title}</title>
        <meta name="description" content={generatedContent} />
        <meta name="og:description" content={generatedContent} />
        <meta name="og:title" content={title} />
        <meta property="og:type" content="website" />
        <link href={canonicalUrl} rel="canonical"></link>
    </Helmet>

    return (
        <div className="blogContent">

            {metadata}

            <div className='blogContainer'>
                <h1>Finanční výkazy</h1>

                <p className='blogText'>
                    Finanční výkazy jsou dokumenty, které popisují finanční stav firem. Finanční výkaz obsahuje výkaz zisku a ztrát a rozvahu (aktiva a pasiva). Finanční výkazy firem můžete prohledávat v aplikaci <a href="/">financni-vykazy.cz</a>.
                </p>

                <img className="blogImage" src={require("./financni vykazy.png")} />
            </div>

            <div className='blogContainer'>
                <h2>Výkaz zisku a ztrát</h2>
                <p className='blogText'>Výkaz zisku a ztrát popisuje výsledek hospodaření za sledované období.</p>
            </div>
            <div className='blogContainer'>
                <h2>Rozvaha - Aktiva</h2>
                <p className='blogText'>Rozvaha - Aktiva je dokument popisující strukturu majetku firmy.</p>

            </div>
            <div className='blogContainer'>
                <h2>Rozvaha - Pasiva</h2>
                <p className='blogText'>Rozvaha - Pasiva je dokument popisující zdroje krytí majetku.</p>
            </div>
        </div>
    )
}

export default FinancniVykazy;
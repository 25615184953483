import financniVykazyDomain from './domainFinancniVykazy.json';
import aktivaDomain from './domainAktiva.json';
import pasivaDomain from './domainPasiva.json';
import cityAktivaDomain from './cityDomainAktiva.json';
import cityPasivaDomain from './cityDomainPasiva.json';
import cityNakladyDomain from './cityDomainNaklady.json';
import cityVynosyDomain from './cityDomainVynosy.json';

function checkStatus(response) {
    if (response.status >= 200 && response.status < 300) {

        if (response.status === 206) {

            if (localStorage.getItem('token') || localStorage.getItem('username')) {
                localStorage.removeItem('username');
                localStorage.removeItem('token');
                window.location.assign("/")
            }

        }

        return response
    }

    if (response.status === 401) {

        localStorage.removeItem('username');
        localStorage.removeItem('token');
        window.location.assign("/")

    }

    else {
        const error = new Error(`HTTP Error ${response.statusText}`);
        error.status = response.statusText;
        error.response = response;
        throw error;
    }
};

function generateHeader(token) {

    return token ? { 'Authorization': `Bearer ${token}` } : {};

}

const debounce = (func, timeout) => {
    let timer;
    return function (...args) {
        const context = this;
        if (timer) clearTimeout(timer);
        timer = setTimeout(() => {
            timer = null;
            func.apply(context, args);
        }, timeout);
    };
};

function getDomainById(domainId) {
    if (domainId === 'vykaz_zisku_ztrat') {
        return financniVykazyDomain;
    }
    else if (domainId === 'aktiva') {
        return aktivaDomain;
    }
    else if (domainId === 'pasiva') {
        return pasivaDomain
    }
    else if (domainId === 'city_assets') {
        return cityAktivaDomain
    }
    else if (domainId === 'city_liabilities') {
        return cityPasivaDomain
    }
    else if (domainId === 'city_expenses') {
        return cityNakladyDomain
    }
    else if (domainId === 'city_revenues') {
        return cityVynosyDomain

    }
}

function cropString(text, maxLength = 40) {
    if (!text) {
        return ""
    }
    else if (text.length > maxLength) {
        return text.slice(0, maxLength) + "...";
    }
    else {
        return text
    }
}

export { checkStatus, generateHeader, debounce, getDomainById, cropString }



import './BigHorizontalBanner.css';
import { useEffect } from 'react';

const BigHorizontalBanner = (props) => {

    const isMobile = window.innerWidth < 720;

    useEffect(() => {
        initAds()
    }, [])

    const zones = [
        {
            "zoneId": props.zoneId,
            "id": props.id,
            "width": isMobile ? 300 : 970,
            "height": isMobile ? 250 : 310
        }
    ]

    const initAds = () => {
        setTimeout(function () {
            window["sssp"].setPageViewId()
            window["sssp"].getAds(zones)
        }, 100);
    }

    return (
        <div className={isMobile ? "smallHorizontalBanner" : "bigHorizontalBanner"}>
            <div id={props.id}></div>
        </div>
    )

}

export default BigHorizontalBanner;


import React from "react";
import './MunicipalityRegionTile.css';
import { Link } from "react-router-dom";

class MunicipalityRegionTile extends React.Component {

    render() {
        return (
            <div className="regionOutterBox">
                <Link to={this.props.regionKey}>
                    <div className="regionTile">
                        <p>{this.props.regionName}</p>
                    </div>
                </Link>
            </div>
        )
    }

}

export default MunicipalityRegionTile;
import React from "react";
import SimilarCompanyTile from "./SimilarCompanyTile";
import './SimilarCompanies.css';
import BigHorizontalBanner from "../Adverts/SeznamAds/BigHorizontalBanner";

class SimilarCompanies extends React.Component {

    render() {

        if (!this.props.companies) {
            return (<p>Načítá se</p>)
        } else {
            return (

                <div>

                    <BigHorizontalBanner
                        zoneId={355948}
                        id="ssp-zone-355948"
                    />

                    <div className="similarCompanies">

                        {this.props.companies.map(c => <SimilarCompanyTile name={c.name}
                            address={c.address}
                            legal_form={c.legal_form}
                            equity_capital={c.equity_capital}
                            web_slug={c.web_slug}
                            url={c.url}
                        />)}
                    </div>

                </div>)
        }
    }
}


export default SimilarCompanies;

function loginSuccessfull(){
    return {
        type: "LOGIN_SUCCESSFULL"
    }
}

function loginFailed(errorMessage){
    return {
        type: 'LOGIN_FAILED',
        errorMessage: errorMessage
    }
}

function initiatingLogin(){
    return {
        type: 'LOGIN_INITIATED'
    }
}

function logoout(){
    return {
        type: 'LOGOUT'
    }
}

export {loginSuccessfull, loginFailed, initiatingLogin, logoout};
import React from "react";
import * as XLSX from 'xlsx';
import { getDomainById } from "../helpers";
import './CompanyXlsxDownloadButton.css';

class CompanyXlsxDownloadButton extends React.Component{

    createXlsxSheet(filename, domainsData) {

        const workbook = XLSX.utils.book_new();

        const domainsDefinition = {
            "vykaz_zisku_ztrat": [{ "id": "current_value", "label": "Hlavní rok" }, { "id": "previous_value", "label": "Předchozí rok" }],
            "aktiva": [{ "id": "current_netto", "label": "Netto" }, { "id": "current_brutto", "label": "Brutto" }, { "id": "current_korekce", "label": "Korekce" }, { "id": "previous_netto", "label": "Minulé Netto" }],
            "pasiva": [{ "id": "current_value", "label": "Hlavní rok" }, { "id": "previous_value", "label": "Předchozí rok" }]
        }

        Object.entries(domainsDefinition).map(([domainId, domainValuesDefinition]) => {

            const sheet = XLSX.utils.json_to_sheet([{}], {});

            const domainLabels = getDomainById(domainId);

            domainsData.filter(domain => domain.id === domainId).map(
                domain => {

                    const yearData = mapFinancialData(domain.values);

                    const uniqueYears = getUniqueYears(yearData);
        
                    const domainItems = getDomainById(domain.id)
                    const domainKeys = Object.keys(domainItems);
        
                    yearData.sort((a, b) => domainKeys.indexOf(a.id) > domainKeys.indexOf(b.id) ? 1 : -1)

                    let domainData = []

                    if (domain.values.length === 0) {
                        domainData = Object.entries(domainLabels).map(([key, value]) => {
                            const itemJson = { "name": value.label, "id": key };
                            uniqueYears(year => itemJson[year] = null);
                            domainValuesDefinition.map(domainValueDefinition => itemJson[domainValueDefinition.id] = "")
                            return itemJson
                        })

                    }
                    else {
                        domainData = yearData.map(item => {

                            const itemJson = { "name": domainLabels[item.id].label, "id": item.id };
                            item.values.map(v => (v.value !== null && !v.value_sources.includes("not_found")) ? itemJson[v.name] = v.value : null)

                            return itemJson
                        })
                    }

                    XLSX.utils.sheet_add_json(sheet, domainData, {header: ['name', 'id',...uniqueYears.map(year => year.toString())]});
                    XLSX.utils.book_append_sheet(workbook, sheet, domainId);

                }
            )

        }
        )

        XLSX.writeFile(workbook, `${filename}.xls`);

    }

    render(){
        return (
            <button className="companyDownloadButton" onClick={(e) => this.createXlsxSheet(this.props.company, this.props.data)}>
                Stáhnout Xlsx data
            </button>
        )
    }

}

function mapFinancialData(financialData) {

    const mapping = Object.keys(financialData).map((key, index) => {

        const sorted = financialData[key].sort((a, b) => (a.year > b.year) ? 1 : -1)

        const data = sorted.map(item => ({ "name": item.year, "value": item.value, "value_sources": item.value_sources }))

        return { id: key, values: data }
    })

    return mapping
}

function getUniqueYears(items) {

    const uniqueYears = [];
    items.map(item => item.values.map(value => {
        if (uniqueYears.indexOf(value.name) === -1) {
            uniqueYears.push(value.name)
        }
    }))

    return uniqueYears;

}

export default CompanyXlsxDownloadButton;
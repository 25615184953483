import React from 'react';
import Login from '../Authentication/Login';

class RegistrationBanner extends React.Component {


    render() {

        return (
            <div>
                <p>Chcete využít funkcionalitu <b>{this.props.functionalityName}</b>?</p>
                <p>Přihlašte se!</p>
                <Login/>
            </div>
        )

    }

}

export default RegistrationBanner;
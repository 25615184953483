function getValueFromRange(field, from, to){

    if (field === "turnover" || field === "liabilities" || field === "assets" || field === 'equity_capital') {

        if(to === null && from === null){
            return "all"
        }

        if (from === 0 && to === 1000000) {
            return "u_1m"
        }
        if (from === 1000000 && to === 10000000) {
            return "u_10m"
        }

        if (from === 10000000 && to === 50000000) {
            return "u_50m"
        }

        if (from === 10000000 && to === 100000000) {
            return "u_100m"
        }

        if (from === 100000000 && to === 500000000) {
            return "u_500m"
        }

        if (from === 100000000 && to === 1000000000) {
            return "u_1000m"
        }

        if (from === 1000000000 && to === null) {
            return "o_1000m"
        }

        return "other"


    }

}


function getRangeFromText(text){

    if (text.endsWith("all")) {
        return { from: null, to: null }
    }

    if (text.endsWith("u_1")) {
        return { from: 0, to: 1 }
    }

    else if (text.endsWith("u_10")) {
        return { from: 0, to: 10 }
    }

    else if (text.endsWith("u_50")) {
        return { from: 10, to: 50 }
    }

    else if (text.endsWith("u_100")) {
        return { from: 50, to: 100 }
    }

    else if (text.endsWith("u_500")) {
        return { from: 100, to: 500 }
    }

    else if (text.endsWith("u_1000")) {
        return { from: 500, to: 1000 }
    }

    else if (text.endsWith("o_1000")) {
        return { from: 1000, to: null }
    }

    else if (text.endsWith("u_1m")) {
        return { from: 0, to: 1000000 }
    }

    else if (text.endsWith("u_10m")) {
        return { from: 1000000, to: 10000000 }
    }

    else if (text.endsWith("u_50m")) {
        return { from: 10000000, to: 50000000 }
    }

    else if (text.endsWith("u_100m")) {
        return { from: 50000000, to: 100000000 }
    }

    else if (text.endsWith("u_500m")) {
        return { from: 100000000, to: 500000000 }
    }

    else if (text.endsWith("u_1000m")) {
        return { from: 500000000, to: 1000000000 }
    }

    else if (text.endsWith("o_1000m")) {
        return { from: 1000000000, to: null }
    }

}


function generateUrl(newState){

    var values = {}

    if (newState.years && newState.years.length > 0) {
        values.years = newState.years
    }

    if (newState.entities && newState.entities.length > 0) {
        values.entities = newState.entities
    }

    if (newState.activities && newState.activities.length > 0) {
        values.activities = newState.activities
    }

    if (newState.employees_count && newState.employees_count.length > 0) {
        values.employees_count = newState.employees_count
    }

    if (newState.regions && newState.regions.length > 0) {
        values.regions = newState.regions
    }

    if (newState.page) {
        values.page = newState.page
    }

    if (newState.pageSize) {
        values.page_size = newState.pageSize
    }

    if (newState.markers) {

        let newDict = {}
        Object.entries(newState.markers).map(([key, value]) => {

            const possibleKeys = {}

            Object.entries(value).map(([key, value]) => {
                if ((key === 'to' || key === 'from')) {
                    possibleKeys[key] = value
                }
            })

            if (Object.keys(possibleKeys).length > 0) {
                newDict[key] = possibleKeys
            }
        })

        if (newDict && Object.keys(newDict).length > 0) {

            values.markers = JSON.stringify(newDict)
        }
    }

    const data = new URLSearchParams(values);

    return data.toString();
}

export {getRangeFromText, getValueFromRange, generateUrl};
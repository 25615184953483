import React from "react";
import { checkStatus } from "../helpers";
import "./PasswordResetEmailRequest.css";

class PasswordResetEmailRequest extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            errorMessage: null,
            successMessage: null,
            loading: false
        }
    }

    handleSubmit = e => {

        e.preventDefault();

        const email = e.target.email.value;

        this.setState({...this.state, loading: true})

        sendResetPassword(email).then(response => {
            
            if (response.status >= 200 && response.status < 300) {
                this.setState({
                    loading: false,
                    errorMessage: null,
                    successMessage: "Zkontrolujte is email."
                });
                return response
            } else {
                const error = new Error(`Problem pri odesilani emailu.`);
            error.status = response.statusText;
            error.response = response;
            throw error;

            }
        })
        .catch(error => this.setState({ loading: false, errorMessage: error.message }));


    }

    inputChange = (e) => {
        this.setState({ ...this.state, [e.target.name]: e.target.value })
    }

    render() {

        return (
            <div className="Form">
                <form className="sendPasswordResetForm" onSubmit={this.handleSubmit}>
                    <p>Zapomněli jste heslo? Vyplňte email, který jste použili pro registraci a my Vám obratem zašleme instrukce pro obnovení hesla.</p>
                    
                    {this.state.successMessage ?
                    <div className="successfullRegistrationBanner"><p>{this.state.successMessage}</p></div>
                    : ""
                }
                    
                    <input className="FormInput" name="email" type="text" onChange={this.inputChange} placeholder="Email použitý při registraci..." />

                    {this.state.errorMessage ? <p className="ErrorMessage">{this.state.errorMessage}</p> : null}

                    {!this.state.loading ?

                        <input className="FormSubmit" type="submit" value="Odeslat" />
                        :
                        <div className="FormSubmit" >
                            <div className="buttonLoaderWrapper">
                                <div className="loader" />
                            </div>
                        </div>


                    }
                </form>
            </div>
        )

    }

}

export default PasswordResetEmailRequest;

function sendResetPassword(email) {

    return fetch(`${process.env.REACT_APP_API_URL}/api/v1/send_reset_password`, {
        method: 'post',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
            email: email
        })
    })
};

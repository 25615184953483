
import React from "react";
import "./LimitationBanner.css";
import { connect } from "react-redux";

class LimitationBanner extends React.Component {

    showLimitationBanner() {

        if (!this.props.token) {
            return (
                <div className="limitationBanner">
                    <p>Platforma může být i bez omezení dotazů a bez reklam.</p>
                    <a className="limitationBannerLink" href="https://www.offiseek.cz/podminky/financni-vykazy/cenik"><b>Ceník</b></a>
                </div>

            )
        }
        else {
            return null
        }

    }

    render() {
        return (
            this.showLimitationBanner()
        )
    }

}

export default connect(mapStateToProps, mapDispatchToProps)(LimitationBanner);

function mapStateToProps(state) {

    return {
        token: localStorage.getItem('token')
    }
}

function mapDispatchToProps(dispatch) {

    return {

    }
}
import React from "react";
import MetricCircle from "./MetricCircle";
import "./MetricsBlock.css";

class MetricsBlock extends React.Component {

    constructor(props) {
        super(props);

        this.czechEnglishMapping = {
            "aktiva_celkem": "assets_total",
            "cizi_zdroje": "other_sources",
            "vysledek_hospodareni_za_ucetni_obdobi": "profit_period",
            "vysledek_hospodareni_po_zdaneni": "profit_after_taxes",
            "vysledek_hospodareni_pred_zdanenim": "profit_before_taxes",
            "cisty_obrat_ucetni_obdobi": "turnover",
            "trzby_prodej_vyrobku_sluzeb": "revenue_products",
            "trzby_prodej_zbozi": "revenue_goods",
            "vlastni_kapital": "equity",
            "zavazky": "obligations",
            "rezervy": "reserves"
        }

        this.assetsKeys = ['aktiva_celkem'];
        this.liabilitiesKeys = ['cizi_zdroje', 'vlastni_kapital', "zavazky", "rezervy"];
        this.profitAndLossKeys = ['vysledek_hospodareni_za_ucetni_obdobi', 'vysledek_hospodareni_po_zdaneni', 'vysledek_hospodareni_pred_zdanenim', 'cisty_obrat_ucetni_obdobi', 'trzby_prodej_vyrobku_sluzeb', 'trzby_prodej_zbozi'];

        this.metrics = {
            "rentability": {
                "label": "Rentabilita tržeb",
                "description": "Rentabilita tržeb říká, kolik si vydělám z každé investované koruny a vypočítá se dle vzorce: Rentabilita tržeb = (zisk / tržby) * 100",
                "format": "percentage"

            },
            "gross_margin": {
                "label": "Hrubá marže",
                "description": "Hrubá marže říká kolik si vydělám (před zdaněním) z každé investované koruny a vypočítá se dle vzorce: Hrubá marže = (zisk před zdaněním / tržby) * 100",
                "format": "percentage"
            },
            "debt_ratio": {
                "label": "Míra zadluženosti",
                "description": "Míra zadluženosti říka, kolik má společnost napůjčených peněz vůči vlastnímu kapitálu a vypočítá se dle vzorce: Míra zadluženosti = (cizí zdroje / vlastní kapitál) * 100",
                "format": "percentage"
            },
            "total_indebtness": {
                "label": "Věřitelské riziko",
                "description": "Věřitelské riziko říká, kolik má společnost napůjčených pěnez vůči celkovému majetku firma a vypočítá se dle vzorce: Věřitelské riziko = (cizí zdroje / aktiva) * 100",
                "format": "percentage"
            },
            "turnover": {
                "label": "Obrat",
                "description": "Obrat společnosti se výpočítá dle vzorce: Obrat = tržby za prodej výrobků a služeb + tržby za prodej zboží",
                "format": "thousands"
            },
            "profit": {
                "label": "Zisk",
                "description": "Zisk společnost se vypočítává dle vzorce: Zisk = tržby - náklady",
                "format": "thousands"
            },
            "assets_total": {
                "label": "Aktiva",
                "description": "Aktiva v podstatě představují celkový majetek společnosti. Obsahují vše, co společnost nabyla minulými aktivitami a co v budoucnu přinese ekonomický prospěch.",
                "format": "thousands"
            }

        }

        this.metricOrder = ['rentability', 'gross_margin', 'debt_ratio', 'total_indebtness', 'turnover', 'profit', 'assets_total']
    }

    goToEconomicMarkers = () => {
        this.props.switchTab('nav_list_tab_Finanční_Stav');
    }

    checkValidationSources = (items) => {
        if (items) {
            return items.reduce((accumulator, item) => (item.includes("x_validate") || item.includes("x_document_validate") && accumulator), true)
        }
        else { return false }
    }

    getVariablesFromDomain = (domain, variablesList) => {

        var ret = {};
        variablesList.map(v => ret[this.czechEnglishMapping[v]] = this.getMostRecentVariableValue(domain.values[v]));
        return ret;
    }

    getValidationSourcesIntersection = (validationSourcesA, validationSourcesB) => {
        return validationSourcesA.filter(v => validationSourcesB.includes(v));
    }

    calculateMetricsFromYearData = (yearData) => {

        // 1. Initialize values
        // 2. What if user is not signed in?
        // 2a. We still have to check what values have $$$ and what are the validation sources...
        // 3. Calculate metrics if user is signed id...

        var assets = yearData.filter(d => d.id === 'aktiva')[0];
        var liabilities = yearData.filter(d => d.id === 'pasiva')[0];
        var profitAndLoss = yearData.filter(d => d.id === 'vykaz_zisku_ztrat')[0];

        if (assets && liabilities && profitAndLoss) {
            const assetsVariables = this.getVariablesFromDomain(assets, this.assetsKeys);
            const liabilitiesVariables = this.getVariablesFromDomain(liabilities, this.liabilitiesKeys);
            const profitAndLossVariables = this.getVariablesFromDomain(profitAndLoss, this.profitAndLossKeys);

            const variables = { ...assetsVariables, ...liabilitiesVariables, ...profitAndLossVariables };

            variables["profit"] = variables["profit_period"].value ? variables["profit_period"] : variables["profit_after_taxes"];
            variables["other_sources"] = variables["other_sources"].value ? variables["other_sources"] : { "value": variables["obligations"].value + variables["reserves"].value, "value_sources": this.getValidationSourcesIntersection(variables["obligations"].value_sources, variables["reserves"].value_sources) }
            variables["turnover"] = variables["turnover"].value ? variables["turnover"] : { "value": variables["revenue_products"].value + variables["revenue_goods"].value, "value_sources": this.getValidationSourcesIntersection(variables["revenue_goods"].value_sources, variables["revenue_products"].value_sources) }
            variables["rentability"] = variables['profit'].value && variables["turnover"].value ? { "value": variables['profit'].value / variables['turnover'].value, "value_sources": this.getValidationSourcesIntersection(variables["profit"].value_sources, variables["turnover"].value_sources) } : { 'value': null, "value_sources": [] };
            variables["gross_margin"] = variables["profit_before_taxes"].value && variables['turnover'].value ? { "value": this.props.user ? variables["profit_before_taxes"].value / variables['turnover'].value : "$$$", 'value_sources': this.getValidationSourcesIntersection(variables['profit_before_taxes'].value_sources, variables['turnover'].value_sources) } : { 'value': null, "value_sources": [] };
            variables["debt_ratio"] = variables['other_sources'].value && variables['equity'].value ? { 'value': variables['other_sources'].value / variables['equity'].value, 'value_sources': this.getValidationSourcesIntersection(variables['other_sources'].value_sources, variables['equity'].value_sources) } : { 'value': null, 'value_sources': [] };
            variables["total_indebtness"] = variables['other_sources'].value && variables['assets_total'].value ? { 'value': variables['other_sources'].value / variables['assets_total'].value, 'value_sources': this.getValidationSourcesIntersection(variables['other_sources'].value_sources, variables['assets_total'].value_sources) } : { 'value': null, 'value_sources': [] };

            Object.keys(variables).map(v => variables[v]['trustworthy'] = this.checkValidationSources(variables[v].value_sources));

            return variables;

        }
        else {
            return null
        }
    }

    getMostRecentVariableValue = (possibleValues) => {
        if (possibleValues) {
            const sortedValues = possibleValues.sort((a, b) => a.year > b.year ? 1 : -1);
            const filteredValues = sortedValues.filter(item => item.value && item.value_sources && !item.value_sources.includes('not_found'));
            const lastValue = filteredValues.at(-1);
            return filteredValues.length > 0 ? { "value": lastValue.value != '$$$' ? parseInt(lastValue.value) : lastValue.value, "value_sources": lastValue.value_sources } : { "value": null, "value_sources": [] };
        } else { return [null, []] }
    }

    render() {

        if (this.props.yearData == null) {
            return (

                <div className="metricsBlock">
                    <h2>Základní finanční metriky subjektu</h2>
                    <div className="metricsHorizontal blurred">
                        <MetricCircle metricName="$$$" />
                        <MetricCircle metricName="$$$" />
                    </div>
                    <p>Metriky jsou vypočítány z vytěžených dat dostupných nahoře v sekci <b>Finanční stav</b>.</p>
                </div>
            )
        }

        const values = this.calculateMetricsFromYearData(this.props.yearData);

        if (values) {
            const filtered = Object.entries(values).filter(([key, value]) => key && key in this.metrics && value.value && value.value != '$$$');
            const sorted = filtered.sort(v => this.metricOrder.indexOf(v[0] ? 1 : -1))
            const metrics = sorted.slice(0, 3).map(metric => <MetricCircle metricName={this.metrics[metric[0]].label}
                metricValue={metric[1].value}
                metricTrustworthiness={metric[1].trustworthy}
                metricDescription={this.metrics[metric[0]].description}
                metricFormat={this.metrics[metric[0]].format}
                isUser={this.props.user !== null}
            />)

            return (
                <div className="metricsBlock">
                    <h2>Základní finanční metriky subjektu</h2>
                    <div className="metricsHorizontal">
                        {metrics}
                    </div>

                    <p>Metriky jsou vypočítány z vytěžených dat dostupných v sekci <b>Finanční stav</b>.</p>
                </div>
            )
        } else {
            return null;
        }
    }
}

export default MetricsBlock;
import React from 'react';
import MemoryIcon from '@mui/icons-material/Memory';
import './RobotsBanner.css';
import CustomTooltip from "../Components/CustomTooltip";

class RobotsBanner extends React.Component {

    render() {
        return (
            <div>
                <CustomTooltip title={<p>Roboti se snaží najít data ve finančních výkazech firem. Je možné, že některé finanční výkazy nejsme schopni spolehlivě zpracovat. <b>Data doporučujeme zkontrolovat a případně upravit.</b></p>}>
                    <div className='robotsTooltip'>
                        <MemoryIcon style={{ fontSize: 20 }} />
                        <p>Data připravují automaticky naši roboti.</p>
                    </div>
                </CustomTooltip>
            </div >
        )
    }
}

export default RobotsBanner;


function documentUploadReducer(state, action){

    if(action.type === 'GET_DOCUMENTS'){

        return {
            ...state,
            queuedDocuments: action.documents
        }

    }

    if(action.type === 'DOCUMENT_SET'){

        return {
            ...state,
            document: action.document
        }

    }

    if(action.type === 'UPLOAD_INITIALIZE'){

        return {
            ...state,
            isProcessing: true,
            }
    }

    else if(action.type === 'UPLOAD_DONE'){

        /* This action will set loader to False  */

        return {
            ...state,
            queuedDocuments: [...state.queuedDocuments, action.data], 
            isProcessing: false,

}
        
    }
    else{

        return state;

    }

}

export default documentUploadReducer;
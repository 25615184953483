import React from "react";
import './Signup.css';

class SignUpForm extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            errorMessage: null,
            loading: false,
            isError: {
                "u_email": "",
                "u_pass": "",
                "u_company": "",
                "u_firstname": "",
                "u_lastname": "",
                "u_telephone": ""
            }
        }
    }

    validateField = (name, value, errorsDict) => {

        switch (name) {

            case "u_email":

                const emailRegex = new RegExp("^[A-Z0-9._%+-]+@[A-Z0-9.-]+\\.[A-Z]{2,4}$", "i")
                errorsDict[name] = !emailRegex.test(value) ? "Email musí být ve správném formátu" : ""
                break

            case "u_pass":

                if (value.length < 10) {
                    errorsDict[name] = "Heslo musí obsahovat aspoň 10 znaků."
                    break
                }

                const charsRegex = RegExp('[a-z]', 'i')
                errorsDict[name] = !(/\d/.test(value) && charsRegex.test(value)) ? "Heslo musí obsahovat aspoň jedno písmeno a aspoň jedno číslo." : ""
                break

            case "u_company":

                errorsDict[name] = value.length < 1 ? "Pole je povinné" : "";
                break

            case "u_firstname":
                errorsDict[name] = value.length < 1 ? "Pole je povinné" : "";
                break

            case "u_lastname":

                errorsDict[name] = value.length < 1 ? "Pole je povinné" : "";
                break

            case "u_telephone":

                const phoneRegex = new RegExp('^(\\+?)(?:\\s*-*\\s*\\d){7,}');

                errorsDict[name] = !phoneRegex.test(value) ? "Číslo musí obsahovat aspoň 7 znaků." : ""

                break

        }

        return errorsDict

    }

    validateInput = e => {

        e.preventDefault();
        const { name, value } = e.target;

        let isError = this.validateField(name, value, this.state.isError)

        this.setState({
            ...this.state,
            isError
        })

    }

    handleSubmit = e => {

        this.setState({
            ...this.state,
            loading: true
        })

        e.preventDefault();

        let isError = this.state.isError
        const email = e.target.u_email.value;
        isError = this.validateField("u_email", email, isError);

        const username = email;

        const password = e.target.u_pass.value;
        isError = this.validateField("u_pass", password, isError);

        const companyName = e.target.u_company.value;
        isError = this.validateField("u_company", companyName, isError);

        const firstName = e.target.u_firstname.value;
        isError = this.validateField("u_firstname", firstName, isError);

        const lastName = e.target.u_lastname.value;
        isError = this.validateField("u_lastname", lastName, isError);

        const telephone = e.target.u_telephone.value;
        isError = this.validateField("u_telephone", telephone, isError)

        let isValid = true;
        Object.values(isError).forEach(val => {
            if (val.length > 0) {
                isValid = false
            }


        });

        const privacyTerms = e.target.privacy_terms.checked;

        if (isValid) {
            this.initiateSignUp(username, email, firstName, lastName, companyName, telephone, password, privacyTerms, this.props.history);
        } else {
            this.setState({
                ...this.state,
                isError: isError,
                loading: false
            })
        }
    }


    initiateSignUp = (username, email, firstName, lastName, companyName, telephone, password, privacyTerms, history) => {

        this.setState({
            errorMessage: null,
            loading: true
        })

        signup(username, email, firstName, lastName, companyName, telephone, privacyTerms, password)
            .then(data => {
                this.setState({
                    loading: false,
                    errorMessage: null
                });
                history.push('/login?registrationRedirect=true&redirectTo=/');
            })
            .catch(error => this.setState({ loading: false, errorMessage: error.message }));

    }

    render() {

        return (
            <div className="Form">
                <form className="signUpForm" onSubmit={this.handleSubmit}>
                    <input className="FormInput" onChange={this.validateInput} placeholder="Jméno" type="text" name="u_firstname" />
                    {this.state.isError.u_firstname.length > 0 ?
                        <span className="formErrorMessage">{this.state.isError.u_firstname}</span>
                        :
                        ""
                    }

                    <input className="FormInput" onChange={this.validateInput} placeholder="Přijmení" type="text" name="u_lastname" />
                    {this.state.isError.u_lastname.length > 0 ?
                        <span className="formErrorMessage">{this.state.isError.u_lastname}</span>
                        :
                        ""
                    }

                    <input className="FormInput" onChange={this.validateInput} placeholder="Firma" type="text" name="u_company" />
                    {this.state.isError.u_company.length > 0 ?
                        <span className="formErrorMessage">{this.state.isError.u_company}</span>
                        :
                        ""
                    }

                    <input className="FormInput" onChange={this.validateInput} placeholder="Telefon" type="text" name="u_telephone" />
                    {this.state.isError.u_telephone.length > 0 ?
                        <span className="formErrorMessage">{this.state.isError.u_telephone}</span>
                        :
                        ""
                    }

                    <input className="FormInput" onChange={this.validateInput} placeholder="Email" type="email" name="u_email" />
                    {this.state.isError.u_email.length > 0 ?
                        <span className="formErrorMessage">{this.state.isError.u_email}</span>
                        :
                        ""
                    }

                    <input className="FormInput" onChange={this.validateInput} placeholder="Heslo" type="password" name="u_pass" />
                    {this.state.isError.u_pass.length > 0 ?
                        <span className="formErrorMessage">{this.state.isError.u_pass}</span>
                        :
                        ""
                    }
                    <p className="termsDiv">Použijte kombinaci písmen, čísel a symbolů. (Nejméně 10 znaků, 1+ písmen, 1+ čísel)</p>


                    <div className="termsCheckboxes">
                        <div className="termsDiv">
                            <input type="checkbox" id="business_terms" name="business_terms" required />
                            <label for="business_terms">Souhlasím s <a href="https://www.offiseek.cz/podminky/financni-vykazy/obchodni-podminky">obchodními podmínkami</a></label>
                        </div>
                        <div className="termsDiv">
                            <input type="checkbox" id="privacy_terms" name="privacy_terms" />
                            <label for="privacy_terms">Souhlasím se zasíláním produktových novinek a jiných sdělení (<a href="https://www.offiseek.cz/podminky/financni-vykazy/ochrana-osobnich-udaju">Ochrana osobních dat)</a></label>
                        </div>
                    </div>

                    {this.state.errorMessage ? <p className="ErrorMessage">{this.state.errorMessage}</p> : null}

                    {!this.state.loading ?

                        <input className="FormSubmit" type="submit" value="Registrovat" />

                        :
                        <div className="FormSubmit" >
                            <div className="buttonLoaderWrapper">
                                <div className="loader" />
                            </div>
                        </div>


                    }

                </form>
            </div>

        )

    }

}


export default SignUpForm;

function signup(username, email, firstName, lastName, companyName, telephone, privacyTerms, password) {

    return fetch(`${process.env.REACT_APP_API_URL}/api/v1/users/signup`, {
        method: 'post',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(
            {
                username: username,
                email: email,
                password: password,
                first_name: firstName,
                last_name: lastName,
                company_name: companyName,
                privacy_terms: privacyTerms,
                telephone, telephone
            }
        )
    }).then(response => {

        if (response.status >= 200 && response.status < 300) {

            return response
        }
        if (response.status === 400) {

            const error = new Error(`Problém při registraci. Email pravděpodobně již používá jiný uživatel.`);
            error.status = response.statusText;
            error.response = response;
            throw error;


        }
        if (response.status === 500) {

            const error = new Error(`Interní chyba na straně aplikace.`);
            error.status = response.statusText;
            error.response = response;
            throw error;

        }
        else {

            const error = new Error(`Neznámá chyba, kontaktujte správce aplikace.`);
            error.status = response.statusText;
            error.response = response;
            throw error;

        }

    })
        .then(response => response.json())
}

import React from "react";
import "./LimitationBanner.css";

class FilteringNotPerfectBanner extends React.Component {

    showLimitationBanner() {

        if (!this.props.token) {
            return (
                <div className="limitationBanner">
                    <p>Zobrazujeme jen subjekty, u kterých byly vytěženy nějaká data.</p>
                </div>

            )
        }
        else {
            return null
        }

    }

    render() {
        return (
            this.showLimitationBanner()
        )
    }

}

export default FilteringNotPerfectBanner;

import moment from 'moment';
import React from 'react';
import { generateHeader } from "../helpers";
import CustomTooltip from "../Components/CustomTooltip";
import "./DocumentDetailsTable.css";

class DocumentDetailTable extends React.Component {

    constructor(props) {
        super(props)
    }

    downloadOriginal = () => {

        const url = new URL(`${this.props.url}`);

        fetch(`${url.pathname}/original`, {
            method: 'get',
            headers: generateHeader(this.props.token)
        }).then((response) => {
            const filename = this.props.url.split("/").pop() + ".pdf";

            response.blob().then(blob => {
                let url = window.URL.createObjectURL(blob);
                let a = document.createElement('a');
                a.href = url;
                a.download = filename;
                document.body.appendChild(a);
                a.click();
                a.parentNode.removeChild(a);

            });
        });



    }

    render() {
        return (

            <div className='detailsTableWrapper'>

                <table className="detailsTable">
                    <tbody>
                        <tr>
                            <td className="headingCell">Název dokumentu:</td>
                            <td>{this.props.filename}</td>
                        </tr>
                        <tr>
                            <td className="headingCell">Datum zpracování:</td>
                            <td>{this.props.createdAt ? moment(this.props.createdAt).format('DD/MM/YYYY hh:mm:ss') : null}</td>
                        </tr>
                        <tr>
                            <td className="headingCell">URL dokumentu:</td>
                            <td><a className='urlBreak' href={`/dokumenty/${this.props.url.split("/").pop()}`}>{`${window.location.hostname}/dokumenty/${this.props.url.split("/").pop()}`}</a></td>
                        </tr>
                        <tr>
                            <td className="headingCell">PDF dokument:</td>
                            <td>

                            {this.props.containsOriginal ? 
                            <div className="downloadOriginal" onClick={this.downloadOriginal}>
                                <CustomTooltip title={<p>PDF dokumentu pro <b>daný rok</b> je vytvořen <b>sloučením</b> všech dostupných dokumentů pro daný rok. Sloučený dokument <b>může</b> obsahovat finanční uzávěrky, notářské zápisy a další dostupné dokumenty. (<b>dlouhé dokumenty zkracujeme.</b>)</p>}>
                                    <a className='documentLink documentLinkPdf' href="#">Stáhnout PDF</a>
                                    </CustomTooltip>
                                    </div> : 
                                    <div className="downloadOriginal">
                                    <CustomTooltip title={<p><b>Originál není možné stáhnout.</b> Uchováváme pouze tři nejnovější originály dokumentů. Starší dokumenty si můžete stáhnout na Obchodním rejstříku.</p>}>
                                        <a className='documentLink documentLinkPdf'>Stáhnout PDF</a>
                                        </CustomTooltip>
                                        </div> 
    }
                            </td>
                        </tr>

                    </tbody>
                </table>
            </div>

        )
    }

}

export default DocumentDetailTable;

import { Helmet } from 'react-helmet';

const UcetniVykazy = () => {

    const generatedContent = "Co jsou účetní výkazy a z čeho se skládájí?";
    const title = "Účetní výkazy";
    const canonicalUrl = "https://financni-vykazy.cz/ucetni-vykazy";

    const metadata = <Helmet>
        <title>{title}</title>
        <meta name="description" content={generatedContent} />
        <meta name="og:description" content={generatedContent} />
        <meta name="og:title" content={title} />
        <meta property="og:type" content="website" />
        <link href={canonicalUrl} rel="canonical"></link>
    </Helmet>

    return (
        <div className="blogContent">

            {metadata}

            <div className='blogContainer'>
                <h1>Účetní výkazy</h1>

                <p className='blogText'>
                    Účetní výkazy firem jsou dokumenty, které jsou výsledkem účetní uzávěrky firem. Účetní výkazy firem by se měly skládat z výkazu zisku a ztrát, rozvahy a připadně z dalších příloh. Finanční výkazy firem můžete prohledávat v aplikaci  <a href="/">financni-vykazy.cz</a>
                </p>

                <img className="blogImage" src={require("./ucetni vykazy.png")} />

            </div>

            <div className='blogContainer'>
                <h2>Účetní závěrka obsahuje</h2>
                <ul>
                    <li className="blogLi">Výkaz zisku a ztrát - popisuje výsledek hospodaření za sledované období.</li>
                    <li className="blogLi">Rozvaha - Aktiva - dokument popisující strukturu majetku firmy.</li>
                    <li className="blogLi">Rozvaha - Pasiva - dokument popisující zdroje krytí majetku.</li>
                </ul>
            </div>
        </div>
    )
}

export default UcetniVykazy;
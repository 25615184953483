import React from "react";
import "./DocumentUpload.css";
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { uploadDocument, uploadDocumentDone, setDocument } from './state/uploadActions'
import { connect } from 'react-redux';
import { checkStatus } from '../helpers';
import DocumentUploadList from "./DocumentUploadList";
import CustomTooltip from '../Components/CustomTooltip';
import WarningIcon from '@mui/icons-material/Warning';
import RegistrationBanner from "../UiRestrictions/RegistrationBanner";

class DocumentUpload extends React.Component {

    constructor(props) {
        super(props);
        this.fileUploadRef = React.createRef();
    }

    render() {

        return (

            <div className="documentUploadMain">

                {!this.props.token ?

                    <RegistrationBanner functionalityName='Instantní zpracování dokumentu' />

                    :
                    <div>
                        <div className="documentUploadForm">
                            <div className="documentUploadDiv" onClick={() => this.fileUploadRef.current.click()}>

                                <CloudUploadIcon style={{ fontSize: 50 }} />
                                <input type="file" hidden ref={this.fileUploadRef} onChange={this.props.setDocument} />

                                <p>Nahrajte dokument obsahující finanční výkazy a během chvíle získejte zpět data ve strukturované podobě.</p>
                                <p>Podporujeme jak textové, tak naskenované PDF. (data vyčítáme z prvních 30 stránek)</p>
                                <p>Prozatím umožňujeme nahrání max. 5ti dokumentů.</p>
                            </div>

                            {(this.props.document && this.props.queuedDocuments.length < 5) ?

                                <div>
                                    <p>Nahraný dokument ke zpracování: {this.props.document.name}</p>
                                    <div className="uploadButtonDiv">
                                        <button className="button" type="submit" onClick={() => this.props.documentUpload(this.props.document, this.props.token)}>Zpracovat dokument</button>
                                    </div>
                                </div>
                                :

                                <div>
                                    <CustomTooltip title="Nejprve nahrajte dokument a následně klikněte na toto tlačítko. Maximální počet nahraných dokumentů je 5." arrow>
                                        <div className="uploadButtonDiv">
                                            <button className="disabledButton button" type="submit" disabled={true}>Zpracovat dokument</button>
                                        </div>
                                    </CustomTooltip>
                                </div>

                            }



                        </div>
                        <div>
                            <div className="uploadWarningBanner">
                                <WarningIcon style={{ color: 'var(--warning)', fontSize: 35 }} />
                                <p>Nezobrazujeme historicky nahrané dokumenty tímto uživatelem. Na této funkcionalitě se pracuje.</p>
                            </div>
                            <DocumentUploadList />
                        </div>

                    </div>

                }

            </div>


        )
    }

}

export default connect(mapStateToProps, mapDispatchToProps)(DocumentUpload);


function analyzeDocument(document, token) {

    const data = new FormData()
    data.append('file', document)

    const headers = token ? { 'Authorization': `Bearer ${token}` } : {};

    return fetch(`${process.env.REACT_APP_API_URL}/api/v1/upload`, {
        method: 'post',
        body: data,
        headers: headers
    }).then(checkStatus)
        .then(response => response.json())
}

function mapStateToProps(state) {

    return {
        filename: state.documentUpload.filename,
        document: state.documentUpload.document,
        isProcessing: state.documentUpload.isProcessing,
        queuedDocuments: state.documentUpload.queuedDocuments,
        token: localStorage.getItem('token')
    }

}

function mapDispatchToProps(dispatch) {

    return {
        documentUpload: (document, token) => {
            dispatch(uploadDocument(document));
            analyzeDocument(document, token).then(data => dispatch(uploadDocumentDone(data)));
        },
        setDocument: (event) => {
            const [file] = event.target.files;
            dispatch(setDocument(file));
        }
    }

}
import React from "react";
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import "./OrLink.css";

class OrLink extends React.Component {

    render() {
        return (
            <div className="orLink">
                <a href={this.props.url} target="_blank">{this.props.label}</a><OpenInNewIcon className="orLinkIcon" />
            </div>
        )
    }

}

export default OrLink;
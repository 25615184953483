import React from "react";
import RawTable from "./RawTable";

class RawTablesList extends React.Component {

    render() {

        const tablesRows = this.props.tables.map(table => {
            var tableArray = new Array(table.row_count).fill('').map(row => new Array(table.column_count).fill(''));

            table.cells.map(cell => {
                tableArray[cell.row_index][cell.column_index] = cell.content
                /*console.log(cell.content);*/
            });

            const tableRows = tableArray.map((tableRow, idx) => (
                <tr key={idx}>
                    {tableRow.map((cell, idx2) => (<td key={idx2}>{cell}</td>))}
                </tr>
            ));

            return [tableRows, table.page]

        });

        return (

            tablesRows.map((tableRows, idx) => (

                <RawTable
                    idx={idx}
                    onClick={this.props.onClick}
                    uncollapsedList={this.props.uncollapsedList}
                    tableRows={tableRows[0]}
                    tablePage={tableRows[1]}
                    key={idx}
                />

            ))


        )
    }

}

export default RawTablesList
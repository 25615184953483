
function filterCompany(entities, years, regions, activities, employees_count, markers, page, pageSize){

    return {
        type: "FILTER_INITIALIZE",
        cutOff: null,
        cutOffLimit: null,
        companies: null,
        companiesTotalCount: null,
        companiesPageCount: null,
        isLoading: true,
        entities: entities,
        years: years,
        regions: regions,
        activities: activities,
        employees_count: employees_count,
        markers: markers,
        page: page,
        pageSize: pageSize
    }

}

function companyFilterDone(companies, totalCount, totalPages, cutOff, cutOffLimit, entities, years, regions, activities, employees_count, markers, page, pageSize){

    return {
        type: "FILTER_DONE",
        companies: companies,
        companiesTotalCount: totalCount,
        companiesPageCount: totalPages,
        cutOff: cutOff,
        cutOffLimit: cutOffLimit,
        isLoading: false,
        entities: entities,
        years: years,
        regions: regions,
        activities: activities,
        employees_count: employees_count,
        markers: markers,
        page: page,
        pageSize: pageSize
    }

}

export {filterCompany, companyFilterDone};
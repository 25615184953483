
import { Helmet } from 'react-helmet';

const DatabazeFiremZdarma = () => {

    const generatedContent = "Databáze firem zdarma umožňuje náhled finančních ukazatelů firem na jedno kliknutí. Zdarma zobrazíme obrat, aktiva a pasiva.";
    const title = "Databáze firem zdarma";
    const canonicalUrl = "https://financni-vykazy.cz/databaze-firem-zdarma";

    const metadata = <Helmet>
        <title>{title}</title>
        <meta name="description" content={generatedContent} />
        <meta name="og:description" content={generatedContent} />
        <meta name="og:title" content={title} />
        <meta property="og:type" content="website" />
        <link href={canonicalUrl} rel="canonical"></link>
    </Helmet>

    return (
        <div className="blogContent">

            {metadata}

            <div className='blogContainer'>
                <h1>Databáze firem zdarma</h1>

                <p className='blogText'>
                    <a href="/">Databáze firem zdarma</a> umožňuje zobrazení obratu, aktiv a pasiv firmy na jedno kliknutí.
                </p>

                <img className="blogImage" src={require("./databaze_firem_zdarma_ukazat data.png")} />

                <p className='blogText'>
                    Po pár sekundách Vám umožníme náhled firemních metrik.

                </p>

                <img className="blogImage" src={require("./databaze_firem_zdarma_metriky.png")} />

                <p className='blogText'>
                    Zdarma Vám zobrazíme i finanční uzávěrku pro daný rok.
                </p>

                <img className="blogImage" src={require("./financni vykazy firmy.png")} />

            </div>

        </div>
    )
}

export default DatabazeFiremZdarma;
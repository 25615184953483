import React from "react";
import { checkStatus } from "../helpers";

class PasswordReset extends React.Component {

    constructor(props) {
        super(props);
        const params = new URLSearchParams(window.location.search);

        this.state = {
            email: params.get("email"),
            token: params.get("token"),
            errorMessage: null,
            successMessage: null,
            loading: false
        }

    }

    validatePasswordField = (password) => {

        const charsRegex = RegExp('[a-z]', 'i')

        if (password.length < 10) {
            this.setState({ ...this.state, errorMessage: "Heslo musí obsahovat aspoň 10 znaků." });
        }

        else if (!(/\d/.test(password) && charsRegex.test(password))) {
            this.setState({ ...this.state, errorMessage: "Heslo musí obsahovat aspoň jedno písmeno a aspoň jedno číslo." })
        }

        else {
            this.setState({ ...this.state, errorMessage: null })
        }

    }



    handleSubmit = e => {

        e.preventDefault();

        const newPassword1 = e.target.newPassword1.value;
        const newPassword2 = e.target.newPassword2.value;

        if (newPassword1 !== newPassword2) {
            this.setState({
                loading: false,
                errorMessage: "Hesla se musí shodovat.",
                successMessage: null
            });
        }

        else {

            this.setState({ ...this.state, loading: true })

            resetPassword(newPassword1, newPassword2, this.state.email, this.state.token).then(response => {

                if (response.status >= 200 && response.status < 300) {
                    this.setState({
                        loading: false,
                        errorMessage: null,
                        successMessage: "Hesla bylo upraveno. Nyní se můžete přihlásit s novým heslem."
                    });
                    return response
                } else {
                    const error = new Error(`Pravděpodobně vypršel link na obnovu hesla. Zašlete prosím další požadavek na změnu hesla.`);
                    error.status = response.statusText;
                    error.response = response;
                    throw error;

                }
            })
                .catch(error => this.setState({ loading: false, errorMessage: error.message }));

        }
    }

    handleInputChange = (e) => {
        this.setState({ ...this.state, [e.target.name]: e.target.value })
        this.validatePasswordField(e.target.value);
    }

    render() {

        return (

            <div className="Form">
                <form className="sendPasswordResetForm" onSubmit={this.handleSubmit}>
                    <p>Obnova hesla pro email <b>{this.state.email}</b></p>

                    {this.state.successMessage ?
                        <div className="successfullRegistrationBanner"><p>{this.state.successMessage}</p></div>
                        : ""
                    }

                    <input className="FormInput" name="newPassword1" type="password" onChange={this.handleInputChange} placeholder="Nové heslo" />
                    <input className="FormInput" name="newPassword2" type="password" onChange={this.handleInputChange} placeholder="Potvrzení nového hesla" />

                    {this.state.errorMessage ? <p className="ErrorMessage">{this.state.errorMessage}</p> : null}

                    {!this.state.loading ?

                        <input className="FormSubmit" type="submit" value="Uložit" />
                        :
                        <div className="FormSubmit" >
                            <div className="buttonLoaderWrapper">
                                <div className="loader" />
                            </div>
                        </div>


                    }
                </form>
            </div>
        )

    }

}

export default PasswordReset;

function resetPassword(newPassword1, newPassword2, username, token) {

    return fetch(`${process.env.REACT_APP_API_URL}/api/v1/reset_password_confirm`, {
        method: 'post',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
            new_password1: newPassword1,
            new_password2: newPassword2,
            username: username,
            token: token
        })
    })
};

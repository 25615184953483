import "./Contact.css";

const Contact = () => {
    return (
        <div className="companyContactInfo">
            <div className="companyInfoRecord"><b>Provozovatel: </b><span>Offiseek s.r.o.</span></div>
            <div className="companyInfoRecord"><b>Web: </b><a href="https://www.offiseek.cz">https://www.offiseek.cz</a></div>
            <div className="companyInfoRecord"><b>Adresa: </b><span>Na spojce 511/9 Vršovice - Praha 10, 101 00</span></div>
            <div className="companyInfoRecord"><b>IČO: </b><span>17731330</span></div>
            <div className="companyInfoRecord"><b>Email: </b><span>support@offiseek.com</span></div>
        </div>
    )
}

export default Contact;
import React from "react";
import './MunicipalityDetailBlock.css';


class MunicipalityDetailBlock extends React.Component {

    constructor(props) {
        super(props);
    }

    render() {

        if (!this.props.region) {
            return (

                <div>
                    <table className="detailsTable">
                        <tbody>
                            <tr>
                                <td className="headingCell">Název:</td>
                                <td>{this.props.name}</td>
                            </tr>
                            <tr>
                                <td className="headingCell">IČO:</td>
                                <td>{this.props.id}</td>
                            </tr>
                            <tr>
                                <td className="headingCell">URL:</td>
                                <td className="cellUrl"><a href={this.props.url}>{`${window.location.hostname}/mesta-obce/.../${this.props.slug}`}</a></td>
                            </tr>

                        </tbody>
                    </table>
                </div>

            )
        }

        return (
            <div>
                <table className="detailsTable">
                    <tbody>
                        <tr>
                            <td className="headingCell">Název:</td>
                            <td>{this.props.name}</td>
                        </tr>
                        <tr>
                            <td className="headingCell">IČO:</td>
                            <td>{this.props.id}</td>
                        </tr>
                        <tr>
                            <td className="headingCell">Region:</td>
                            <td>{this.props.region}</td>
                        </tr>
                        <tr>
                            <td className="headingCell">URL:</td>
                            <td className="cellUrl"><a href={this.props.url}>{`${window.location.hostname}/firmy/.../${this.props.slug}`}</a></td>
                        </tr>
                        
                        <tr>
                            <td className="headingCell">Počet obyvatel:</td>
                            <td>{this.props.population}</td>
                        </tr>

                    </tbody>
                </table>
            </div>
        )
    }

}

export default MunicipalityDetailBlock;
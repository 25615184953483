import React from "react";
import './SearchGuide.css';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import RegionTile from "./RegionTile";
import StatsDashboard from "../MacroeconomicStats/ForeignCompaniesOwners";

const regionsMap = {
    "kralovehradecky-kraj": "Královéhradecký kraj",
    "stredocesky-kraj": "Středočeský kraj",
    "karlovarsky-kraj": "Karlovarský kraj",
    "plzensky-kraj": "Plzeňský kraj",
    "pardubicky-kraj": "Pardubický kraj",
    "zlinsky-kraj": "Zlínský kraj",
    "kraj-vysocina": "Kraj Vysočina",
    "hlavni-mesto-praha": "Hlavní město Praha",
    "liberecky-kraj": "Liberecký kraj",
    "jihocesky-kraj": "Jihočeský kraj",
    "jihomoravsky-kraj": "Jihomoravský kraj",
    "moravsko-slezsky": "Moravskoslezský kraj",
    "ustecky-kraj": "Ústecký kraj",
    "olomoucky-kraj": "Olomoucký kraj"
}

class SearchGuide extends React.Component {

    handleRowOnClick = (url) => {

        const u = new URL(url)
        this.props.history.push(u.pathname);

    }

    render() {
        return (
            <div className="searchGuideMain">
                <div className="searchGuideOffer">
                    <div className="searchGuideTile oneReport">
                        <h3>Firmy</h3>
                        <p>Až 30krát denně můžete prozkoumat vytěžená data firem <b>zdarma</b>.</p>
                        <b>Bez relkam?</b> <a href="https://www.offiseek.cz/podminky/financni-vykazy/cenik" target="_blank">Zkontrolujte ceník.</a>
                    </div>
                    <div className="searchGuideTile onlinePlatform">
                        <h3>Města a obce</h3>
                        <p>Zajímá Vás hospodaření vašeho města nebo obce?</p>
                        <b>Vyhledávejte</b> <a href="/mesta-obce" target="_blank">zde.</a>
                    </div>
                    <div className="searchGuideTile exportData">
                        <h3>Statistiky ČR</h3>
                        <p>Jak se daří segmentům české ekonomiky? Kolik vlastníků je z ČR?</p>
                        <a href="/statistika-zahranicni-firmy-cr" target="_blank">Více informací</a>
                    </div>
                </div>

                <div className="regionsBlock">
                    <h2>Řazení firem dle kraje</h2>
                    <div className="regionsBlock">
                        {Object.entries(regionsMap).map(([key, value]) => <RegionTile
                            regionName={value}
                            regionKey={`region/${key}/filtrovani-firem?regions=${value}`} />)}
                    </div>
                </div>
            </div>
        )
    }

}

export default SearchGuide;